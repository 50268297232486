// File: src/utils/loadWeeklyData.js
import axios from 'axios';
import { getBaseUrl } from './getBaseUrl';

/**
 * Formats a Date object as "YYYY-MM-DD" based on local time.
 */
function formatLocalDate(date) {
  const year = date.getFullYear();
  // getMonth() returns 0-11, so add 1 and pad.
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * Returns the Monday date (in YYYY-MM-DD) and Sunday date (in YYYY-MM-DD)
 * that we want to load, based on "today’s" date logic:
 *  - If today is Monday => load the *previous* Monday–Sunday
 *  - If today is Tue–Sun => load the *current* Monday–Sunday
 */
function determineWeekRange() {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0=Sun, 1=Mon, 2=Tue, ...
  let targetMonday = new Date(today);

  if (dayOfWeek === 1) {
    // If it's Monday => load the *previous* Monday.
    targetMonday.setDate(targetMonday.getDate() - 7);
  } else {
    // For Tue–Sun, calculate the current Monday.
    const offset = (dayOfWeek + 6) % 7; 
    targetMonday.setDate(targetMonday.getDate() - offset);
  }

  const mondayStr = formatLocalDate(targetMonday);

  // Sunday is 6 days after Monday.
  const sundayDate = new Date(targetMonday);
  sundayDate.setDate(sundayDate.getDate() + 6);
  const sundayStr = formatLocalDate(sundayDate);

  return { startStr: mondayStr, endStr: sundayStr };
}

/**
 * loadWeeklyData(stores):
 *  - Determine the relevant Monday–Sunday using local time.
 *  - Make an API call to your backend to load the data for each store.
 *  - Return an object keyed by storeId.
 */
export async function loadWeeklyData(stores) {
  if (!stores || !stores.length) return {};

  // 1) Determine the range using our local date formatting.
  const { startStr, endStr } = determineWeekRange();

  // 2) Build a comma-separated list of store IDs.
  const storeIds = stores.map(s => s.store_id).join(',');

  // 3) Call your server.
  const url = `${getBaseUrl()}/pfield_wsn/weekData?start=${startStr}&end=${endStr}&storeIds=${storeIds}`;

  try {
    const res = await axios.get(url, { withCredentials: true });
    // Suppose it returns an object like { "2410": {...}, "6989": {...} }
    return res.data || {};
  } catch (err) {
    console.error('Error fetching weekly data =>', err);
    return {};
  }
}
