export function getMonthlyWarnings(monthlyData = [], cycleLength = 3) {
  const warnings = [];

  function buildAfter8Phrase(cycleLen) {
    if (cycleLen === 3) return "this quarter";
    if (cycleLen === 6) return "this 6-Month Cycle";
    if (cycleLen === 12) return "this year";
    return `in ${cycleLen} months`;
  }

  // If no monthly data is provided, flag an overall warning.
  if (monthlyData.length === 0) {
    warnings.push(`[WARNING] No monthly schedule data available for this supervisor.`);
    return warnings;
  }

  monthlyData.forEach((row) => {
    const storeName = row.storeName || row.combined_id;

    // Warning if there has been no night visit (5pm or later) this month.
    if (row.nightVisits === 0) {
      warnings.push(`[WARNING] ${storeName} has not had a night visit (5pm or later) this month.`);
    }

    // Caution if there has been no check-in after 8pm.
    if (row.after8Visits === 0) {
      const phrase = buildAfter8Phrase(cycleLength);
      warnings.push(`[CAUTION] ${storeName} has not had a 'Check-In' after 8pm ${phrase}.`);
    }

    // Warning if there has been no weekend visit.
    if (row.weekendVisits === 0) {
      warnings.push(`[WARNING] ${storeName} has not had a weekend visit this month.`);
    }

    // Additional checks for workingWithMITCount or gmNotPresentCount can be added here.
  });

  return warnings;
}