/**
 * Calculates weekly warnings from the provided weeklyData array.
 * Expected format (from the dashboard response):
 * [
 *   { combined_id: "2410", storeName: "48TH STREET", scheduled: 8, required: 0, gap: 0 },
 *   { combined_id: "6989", storeName: "HOLDREGE", scheduled: 8, required: 0, gap: 0 }
 * ]
 *
 * Returns an array of warning strings.
 */
export function getWeeklyWarnings(weeklyData = []) {
  const warnings = [];
  console.log('[WeeklyRequ log WEEKLY DATA]: ', weeklyData);
  
  // Get store rows (exclude Office and Vacation)
  const storeRows = weeklyData.filter(row => row.combined_id !== 'Office' && row.combined_id !== 'Vacation');
  // Sum of store hours
  const totalStoreHours = storeRows.reduce((acc, row) => acc + (row.scheduled || 0), 0);
  
  // Get Office hours (if any)
  const officeRow = weeklyData.find(row => row.combined_id === 'Office');
  const officeHours = officeRow ? (officeRow.scheduled || 0) : 0;
  
  // Total scheduled hours include both store and Office hours
  const totalScheduled = totalStoreHours + officeHours;
  
  // Calculate required hours based on the number of unique stores
  const uniqueStores = new Set(storeRows.map(row => row.storeName || row.combined_id));
  const storeCount = uniqueStores.size;
  const requiredHours = storeCount >= 4 ? 45 : storeCount * 8;
  if (totalScheduled < requiredHours) {
    warnings.push(`[WARNING] Scheduled less than the required ${requiredHours} hours this week.`);
  }
  
  // 2) Check that office hours do not equal or exceed 10% of overall important hours (Stores + Meetings).
  if (officeRow) {
    if (totalStoreHours > 0 && officeHours >= 0.1 * totalStoreHours) {
      warnings.push(`[WARNING] Office hours (${officeHours} hrs) are 10% or more of your store & meeting hours.`);
    }
  }
  
  // 3) Each store must be scheduled at least 4 hours.
  storeRows.forEach(row => {
    if ((row.scheduled || 0) < 4) {
      const storeName = row.storeName || row.combined_id;
      warnings.push(`[WARNING] ${storeName} is not scheduled at least 4 hours this week.`);
    }
  });
  
  return warnings;
}
