import React from 'react';
import {
  Box,
  Text,
  Input,
  Textarea,
  useColorModeValue
} from '@chakra-ui/react';

/**
 * Displays two fields:
 *  1) "spent" (How much was spent?)
 *  2) "summary" (Summary of how the money was spent)
 *
 * Expects:
 *  - fundsData: { spent: string, summary: string }
 *  - onChange: (newFundsData) => void
 */
export default function EmployeeFundsSection({ 
  fundsData = { spent: '', summary: '' }, 
  onChange 
}) {
  // A helper to update one field in the object
  function updateField(field, value) {
    onChange({ ...fundsData, [field]: value });
  }

  // Example optional color
  const bg = useColorModeValue('white', 'gray.800');

  return (
    <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md" bg={bg}>
      <Text fontWeight="semibold" mb={1}>How much was spent this month?</Text>
      <Input
        type="number"
        placeholder="Enter amount"
        value={fundsData.spent}
        onChange={(e) => updateField('spent', e.target.value)}
        size="sm"
        mb={4}
      />

      <Text fontWeight="semibold" mb={1}>Summary of how the money was spent:</Text>
      <Textarea
        placeholder="e.g. Team lunch, gift cards, etc."
        value={fundsData.summary}
        onChange={(e) => updateField('summary', e.target.value)}
        size="sm"
      />
    </Box>
  );
}
