import React, { useRef } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Button,
  IconButton,
  Textarea,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useColorModeValue
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import WillingToWorkAtStores from './WillingToWorkAtStores';

export default function MITSection({
  mitCards = [],
  onChange
}) {
  const fileInputRefs = useRef({});
  const cardBg = useColorModeValue('white', 'gray.800');

  // Update a single card field.
  function updateCardField(index, field, value) {
    const newMitCards = [...mitCards];
    newMitCards[index] = { ...newMitCards[index], [field]: value };
    onChange(newMitCards);
  }

  // Add a new MIT card.
  function handleAdd() {
    const newMitCards = [
      ...mitCards,
      {
        name: '',
        rating: 5,
        timeSpent: '',
        accomplishments: '',
        willingStores: [],
        mitPhotoFile: null,
        mitPhotoUrl: null
      }
    ];
    onChange(newMitCards);
  }

  // Remove an existing MIT card.
  function handleRemove(index) {
    const newMitCards = mitCards.filter((_, i) => i !== index);
    onChange(newMitCards);
  }

  // The "No MIT" handler clears all MIT cards from the modal.
  function handleNoMIT() {
    // Clear out every MIT card.
    onChange([]);
  }

  // Attach a photo for the MIT card.
  function attachPhoto(index) {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].click();
    }
  }

  function handleFileChange(index, e) {
    const file = e.target.files?.[0] || null;
    const newMitCards = [...mitCards];
    newMitCards[index] = {
      ...newMitCards[index],
      mitPhotoFile: file
    };
    onChange(newMitCards);
  }

  return (
    <>
      <HStack mb={4}>
        <Button
          size="sm"
          variant="outline"
          colorScheme="blue"
          onClick={handleAdd}
        >
          Add MIT
        </Button>
        <Button
          size="sm"
          variant="outline"
          colorScheme="red"
          onClick={handleNoMIT}
        >
          No MIT
        </Button>
      </HStack>

      {mitCards.length === 0 ? (
        <Text fontSize="sm" color="gray.600">
          No MIT cards created.
        </Text>
      ) : (
        <VStack spacing={4} align="stretch">
          {mitCards.map((card, index) => (
            <Box
              key={index}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              boxShadow="md"
              bg={cardBg}
            >
              <HStack justify="space-between" mb={2}>
                <Heading size="sm">MIT Card #{index + 1}</Heading>
                {mitCards.length > 1 && (
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Remove MIT Card"
                    size="xs"
                    variant="outline"
                    colorScheme="red"
                    onClick={() => handleRemove(index)}
                  />
                )}
              </HStack>

              {/* MIT Name */}
              <Box mt={2}>
                <Text fontWeight="semibold" mb={1}>MIT Name:</Text>
                <Input
                  size="sm"
                  placeholder="Enter MIT's name"
                  value={card.name}
                  onChange={(e) => updateCardField(index, 'name', e.target.value)}
                />
              </Box>

              {/* Rating */}
              <Box mt={2}>
                <Text fontWeight="semibold" mb={1}>Rating (1-10):</Text>
                <Slider
                  value={card.rating}
                  min={1}
                  max={10}
                  step={1}
                  onChange={(val) => updateCardField(index, 'rating', val)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={6} />
                </Slider>
                <Text fontSize="sm" mt={1}>Rating: {card.rating}</Text>
              </Box>

              {/* Time Spent */}
              <Box mt={2}>
                <Text fontWeight="semibold" mb={1}>Time Spent with MIT:</Text>
                <Textarea
                  size="sm"
                  placeholder="When did you spend time?"
                  value={card.timeSpent}
                  onChange={(e) => updateCardField(index, 'timeSpent', e.target.value)}
                />
              </Box>

              {/* Accomplishments */}
              <Box mt={2}>
                <Text fontWeight="semibold" mb={1}>Accomplishments:</Text>
                <Textarea
                  size="sm"
                  placeholder="What was accomplished?"
                  value={card.accomplishments}
                  onChange={(e) => updateCardField(index, 'accomplishments', e.target.value)}
                />
              </Box>

              {/* Willing to Work at */}
              <Box mt={2}>
                <Text fontWeight="semibold" mb={1}>Willing to Work at:</Text>
                <WillingToWorkAtStores
                  selectedStores={card.willingStores}
                  onChange={(values) => {
                    const newMitCards = [...mitCards];
                    newMitCards[index] = { ...newMitCards[index], willingStores: values };
                    onChange(newMitCards);
                  }}
                />
              </Box>

              {/* Attach Photo */}
              <Button
                size="sm"
                mt={3}
                variant="outline"
                colorScheme="blue"
                onClick={() => attachPhoto(index)}
              >
                Attach Photo
              </Button>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={(el) => (fileInputRefs.current[index] = el)}
                onChange={(e) => handleFileChange(index, e)}
              />

              {/* Show preview for newly attached photo or existing URL */}
              {card.mitPhotoFile ? (
                <>
                  <Text fontSize="sm" mt={1}>
                    Attached MIT File: {card.mitPhotoFile.name}
                  </Text>
                  <Box mt={2}>
                    <img
                      src={URL.createObjectURL(card.mitPhotoFile)}
                      alt="New MIT Photo Preview"
                      style={{ maxWidth: '100px', maxHeight: '80px' }}
                    />
                  </Box>
                </>
              ) : (
                card.mitPhotoUrl && (
                  <Box mt={2}>
                    <Text fontSize="sm">Existing photo:</Text>
                    <img
                      src={card.mitPhotoUrl}
                      alt="Existing MIT Photo"
                      style={{ maxWidth: '100px', maxHeight: '80px' }}
                    />
                  </Box>
                )
              )}
            </Box>
          ))}
        </VStack>
      )}
    </>
  );
}
