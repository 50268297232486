import React from 'react';
import PropTypes from 'prop-types';

function getDynamicRatingGradient(rating) {
  const red = { r: 210, g: 70, b: 70 };      // Rich red
  const gold = { r: 240, g: 220, b: 100 };     // Warm golden (target for rating 5)
  const green = { r: 90, g: 180, b: 90 };      // Natural green (target for rating 10)

  let target;
  if (rating <= 5) {
    const ratio = rating / 5;
    target = {
      r: Math.round(red.r + (gold.r - red.r) * ratio),
      g: Math.round(red.g + (gold.g - red.g) * ratio),
      b: Math.round(red.b + (gold.b - red.b) * ratio)
    };
  } else {
    const ratio = (rating - 5) / 5;
    target = {
      r: Math.round(gold.r + (green.r - gold.r) * ratio),
      g: Math.round(gold.g + (green.g - gold.g) * ratio),
      b: Math.round(gold.b + (green.b - gold.b) * ratio)
    };
  }
  return `linear-gradient(to right, rgb(${red.r}, ${red.g}, ${red.b}), rgb(${target.r}, ${target.g}, ${target.b}))`;
}

export default function StoreByStoreView({
  currentStorePage,
  storeDisplayName,
  currentStoreIndex,
  totalStores,
  goToPreviousStore,
  goToNextStore,
  storesData
}) {
  if (!currentStorePage) {
    return <p>No store notes available.</p>;
  }

  return (
    <div className="store-notes">
      <div className="store-header">
        <button className="nav-button" onClick={goToPreviousStore} disabled={currentStoreIndex === 0}>
          &#8592;
        </button>
        <span className="store-name">{storeDisplayName}</span>
        <button className="nav-button" onClick={goToNextStore} disabled={currentStoreIndex === totalStores - 1}>
          &#8594;
        </button>
      </div>
      <p className="page-indicator">
        Store {currentStoreIndex + 1} of {totalStores}
      </p>
      <div className="store-details">
        <div className="info-section">
          <p className="info-title">People</p>
          <p className="info-content">{currentStorePage[1].people || 'N/A'}</p>
        </div>
        <div className="info-section">
          <p className="info-title">Operations</p>
          <p className="info-content">{currentStorePage[1].operations || 'N/A'}</p>
        </div>
        <div className="info-section">
          <p className="info-title">Profit</p>
          <p className="info-content">{currentStorePage[1].profit || 'N/A'}</p>
        </div>
        <div className="info-section">
          <p className="info-title">Sales</p>
          <p className="info-content">{currentStorePage[1].sales || 'N/A'}</p>
        </div>
        {currentStorePage[1].followUpTasks &&
          currentStorePage[1].followUpTasks !== 'N/A' &&
          currentStorePage[1].followUpTasks.trim() !== '' && (
            <div className="info-section">
              <p className="info-title">Follow Up Tasks</p>
              <p className="info-content">{currentStorePage[1].followUpTasks}</p>
            </div>
          )}
        {currentStorePage[1].employeeFunds &&
          (Number(currentStorePage[1].employeeFunds.spent) !== 0 ||
            (currentStorePage[1].employeeFunds.summary &&
              currentStorePage[1].employeeFunds.summary.trim() !== '')) && (
            <div className="info-section">
              <p className="info-title">Employee Funds</p>
              <p className="info-content">
                {currentStorePage[1].employeeFunds.spent !== 0 && <>Spent: {currentStorePage[1].employeeFunds.spent} </>}
                {currentStorePage[1].employeeFunds.summary &&
                  currentStorePage[1].employeeFunds.summary.trim() !== '' && (
                    <>Summary: {currentStorePage[1].employeeFunds.summary}</>
                  )}
              </p>
            </div>
          )}
      </div>
      {currentStorePage[1].mitCards &&
        currentStorePage[1].mitCards.length > 0 && (
          <div className="mit-cards">
            <h6>MIT Section</h6>
            <div className="mit-header">
              <span className="mit-name-col">Name</span>
              <span className="mit-rating-col">Rating</span>
            </div>
            {currentStorePage[1].mitCards.map((card, index) => (
              <div key={index} className="mit-card">
                <div className="mit-card-row">
                  <span className="mit-name-col">{card.name}</span>
                  <span className="mit-rating-col">
                    <div className="rating-bar">
                      <div
                        className="rating-fill"
                        style={{
                          width: `${(card.rating / 10) * 100}%`,
                          background: getDynamicRatingGradient(card.rating)
                        }}
                      ></div>
                      <div className="rating-indicators">
                        <div className="tick" style={{ left: '0%' }}></div>
                        <div className="tick" style={{ left: '25%' }}></div>
                        <div className="tick" style={{ left: '50%' }}></div>
                        <div className="tick" style={{ left: '75%' }}></div>
                        <div className="tick" style={{ left: '100%' }}></div>
                      </div>
                    </div>
                  </span>
                </div>
                <div className="mit-card-details">
                  <div className="info-section">
                    <p className="info-title">Time Spent</p>
                    <p className="info-content">{card.timeSpent}</p>
                  </div>
                  <div className="info-section">
                    <p className="info-title">Accomplishments</p>
                    <p className="info-content">{card.accomplishments}</p>
                  </div>
                  <div className="info-section">
                    <p className="info-title">Willing Stores</p>
                    <p className="info-content">
                      {Array.isArray(card.willingStores) && card.willingStores.length > 0
                        ? card.willingStores
                            .map(storeId => {
                              const storeInfo = storesData.find(s => String(s.store_id) === String(storeId));
                              return storeInfo ? storeInfo.store_name : storeId;
                            })
                            .join(', ')
                        : 'N/A'}
                    </p>
                  </div>
                  {card.mitPhotoUrl && (
                    <div className="mit-photo-container">
                      <img src={card.mitPhotoUrl} alt={`${card.name} photo`} className="mit-photo" />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
    </div>
  );
}

StoreByStoreView.propTypes = {
  currentStorePage: PropTypes.array,
  storeDisplayName: PropTypes.string,
  currentStoreIndex: PropTypes.number.isRequired,
  totalStores: PropTypes.number.isRequired,
  goToPreviousStore: PropTypes.func.isRequired,
  goToNextStore: PropTypes.func.isRequired,
  storesData: PropTypes.array.isRequired,
};
