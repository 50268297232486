// File: src/components/ProlifiField/Schedules/DirectorScheduleSection.jsx

import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
  Month,
  DragAndDrop,
  Resize,
  ResourcesDirective,
  ResourceDirective,
  Inject
} from '@syncfusion/ej2-react-schedule';
import axios from 'axios';

import LegacyDirectorModal from './director/LegacyDirectorModal';
import DirectorCustomModal from './director/DirectorCustomModal';

import ShiftTooltip from './super/ShiftTooltip';
import { validateShift } from '../../../utils/directorShiftValidation';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import SupervisorHoursOverlay from './SupervisorHoursOverlay';
import SupervisorWeeklyStoreNotesModal from '../wsn/director/SupervisorWeeklyStoreNotesModal';

function formatLocalDateTime(date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) return '';
  const pad = n => String(n).padStart(2,'0');
  const yyyy = date.getFullYear();
  const mm   = pad(date.getMonth()+1);
  const dd   = pad(date.getDate());
  const HH   = pad(date.getHours());
  const MM   = pad(date.getMinutes());
  const SS   = pad(date.getSeconds());
  return `${yyyy}-${mm}-${dd} ${HH}:${MM}:${SS}`;
}

function areDatesOnSameDay(d1,d2) {
  return (
    d1.getFullYear()===d2.getFullYear() &&
    d1.getMonth()===d2.getMonth() &&
    d1.getDate()===d2.getDate()
  );
}

const DirectorScheduleSection = forwardRef(function DirectorScheduleSection(props, ref) {
  const {
    events,
    setEvents,
    supervisorResource = [],
    myDirectorResourceId, // negative ID for active director
    directorSupId = null,        // if director is also a sup, store that sup's ID
    directorAreas = [],
    directorStores = [],
    directorName = 'Unknown Director',
    onEventsChanged,
    onDateRangeChange,
    startDate,
    endDate
  } = props;

  const scheduleRef = useRef(null);
  const overlayRef  = useRef(null);
	const [isSupervisorNotesModalOpen, setIsSupervisorNotesModalOpen] = useState(false);
	const [selectedSupervisorId, setSelectedSupervisorId] = useState(null);

  // Director modals
  const [isLegacyDirectorModalOpen, setIsLegacyDirectorModalOpen] = useState(false);
  const [directorRowEvent, setDirectorRowEvent] = useState(null);

  // Director's supervisor modals
  const [isSupModalOpen, setIsSupModalOpen] = useState(false);
  const [supervisorRowEvent, setSupervisorRowEvent] = useState(null);

  // For hours overlay
  const [supervisorHoursMap, setSupervisorHoursMap] = useState({});
  const [nameToIdMap, setNameToIdMap] = useState({});

  // For completed timesheet (like a checkmark)
  const [completedSupIds, setCompletedSupIds] = useState(new Set());

  // Track the schedule's selected date
  const [selectedDate, setSelectedDate] = useState(new Date());

  // For drag/resize revert logic
  const originalTimesRef = useRef({});
  
  console.log("[DIRECTOR RESOURCE ID]: ", myDirectorResourceId);

  useImperativeHandle(ref, () => ({
    switchView(viewName) {
      if (scheduleRef.current) {
        scheduleRef.current.currentView = viewName;
        scheduleRef.current.dataBind();
      }
    },
    refresh() {
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    }
  }));

  // --------------------------------------------------------------------------
  // fetchCompletedWeeks => we call the /pfield_director/completedWeeks route
  // --------------------------------------------------------------------------
  async function fetchCompletedWeeks(startLocal, endLocal) {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/completedWeeks`;
      const payload = { startDate: startLocal, endDate: endLocal };
      const resp = await axios.post(url, payload, { withCredentials: true });
      console.log('[fetchCompletedWeeks] =>', resp.data);

      const newSet = new Set();
      if (resp.data && resp.data.completedWeeks) {
        resp.data.completedWeeks.forEach((w) => {
          if (w.status==='complete' && w.user_id) {
            newSet.add(w.user_id);
          }
        });
      }
      setCompletedSupIds(newSet);
    } catch (err) {
      console.error('Error fetching completed weeks =>', err);
    }
  }

  // --------------------------------------------------------------------------
  // DELETE director event
  // --------------------------------------------------------------------------
  async function deleteDirectorShift(id) {
    try {
      const baseUrl = getBaseUrl();
      const delUrl  = `${baseUrl}/pfield_events/pfield_director/directorEvent/${id}`;
      await axios.delete(delUrl, { withCredentials:true });
      console.log('Deleted director event =>', id);

      setEvents(prev => prev.filter(e => e.Id !== id));
    } catch (err) {
      console.error('Error deleting director event =>', err);
      alert('Error deleting director event. Check console for details.');
    }
  }

  // --------------------------------------------------------------------------
  // DELETE supervisor shift
  // --------------------------------------------------------------------------
  async function deleteSupervisorShiftInDb(shiftId) {
    try {
      const baseUrl = getBaseUrl();
      const delUrl  = `${baseUrl}/pfield_events/pfield_director/supervisorEvent/${shiftId}`;
      await axios.delete(delUrl, { withCredentials:true });
      console.log('Supervisor shift deleted =>', shiftId);

      setEvents(prev => prev.filter(e => e.Id !== shiftId));
    } catch (err) {
      console.error('Error deleting supervisor shift =>', err);
      alert('Error deleting supervisor shift. Check console for details.');
    }
  }

  // --------------------------------------------------------------------------
  // Recompute name->id map + total hours for sups
  // --------------------------------------------------------------------------
useEffect(() => {
  const newNameMap = {};
  (supervisorResource || []).forEach(r => {
    // For this approach we only need the id.
    // Director rows have negative ids; supervisor rows have positive ids.
    newNameMap[r.text] = { id: r.id };
  });
  setNameToIdMap(newNameMap);

  // Calculate total hours for supervisors (only positive IDs)
  const newHoursMap = {};
  events.forEach(ev => {
    // Only calculate for positive IDs
    if (ev.SupervisorId && ev.SupervisorId > 0) {
      const s = new Date(ev.startTime);
      const e = new Date(ev.endTime);
      if (!isNaN(s) && !isNaN(e)) {
        const hrs = (e - s) / (1000 * 60 * 60);
        newHoursMap[ev.SupervisorId] = (newHoursMap[ev.SupervisorId] || 0) + hrs;
      }
    }
  });
  setSupervisorHoursMap(newHoursMap);
}, [events, supervisorResource]);



  // --------------------------------------------------------------------------
  // On mount => if timeline => fetch completed weeks
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (scheduleRef.current) {
      if (scheduleRef.current.currentView==='TimelineWeek') {
        const vDates = scheduleRef.current.getCurrentViewDates();
        if (vDates?.length>0) {
          const earliest = new Date(vDates[0]);
          earliest.setHours(0,0,0,0);
          const latest = new Date(vDates[vDates.length-1]);
          latest.setHours(23,59,59,999);

          const earliestStr = formatLocalDateTime(earliest);
          const latestStr   = formatLocalDateTime(latest);
          fetchCompletedWeeks(earliestStr, latestStr);
        }
      }
    }
  }, []);

  // --------------------------------------------------------------------------
  // If parent doesn't have start/end => set from visible range
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (scheduleRef.current && (!startDate || !endDate)) {
      const vDates = scheduleRef.current.getCurrentViewDates();
      if (vDates?.length>0) {
        const earliest = new Date(vDates[0]);
        earliest.setHours(0,0,0,0);
        const latest = new Date(vDates[vDates.length-1]);
        latest.setHours(23,59,59,999);

        onDateRangeChange?.(
          formatLocalDateTime(earliest),
          formatLocalDateTime(latest)
        );
      }
    }
  }, [onDateRangeChange, startDate, endDate]);

  // --------------------------------------------------------------------------
  // Resource header => check if "completed"
  // --------------------------------------------------------------------------
function resourceHeaderTemplate(props) {
  const isTimelineWeek = (scheduleRef.current?.currentView === 'TimelineWeek');
  const isComplete = completedSupIds.has(props.resourceData.id);
  const isDirector = isTimelineWeek && props.resourceData.isDirector;

  const containerStyle = {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    cursor: !isDirector ? 'pointer' : 'default' // indicate clickable for supervisors
  };

  const innerStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    marginLeft: '25px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    ...(isDirector
      ? {
          borderBottom: '2px solid gold',
          boxShadow: '0 0 8px gold',
          backgroundColor: '#fffbe6'
        }
      : {})
  };

  // If it's a supervisor row, attach an onClick to trigger the modal.
  const handleClick = () => {
    if (!isDirector) {
      // Assuming you have access to these setter functions in scope:
      setSelectedSupervisorId(props.resourceData.id);
      setIsSupervisorNotesModalOpen(true);
    }
  };

  return (
    <div style={containerStyle} onClick={!isDirector ? handleClick : undefined}>
      <div style={innerStyle}>
        <span>{props.resourceData.text}</span>
        {isTimelineWeek && isComplete && (
          <span style={{ marginLeft: 8, color: 'green', fontWeight: 'bold' }}>
            ✔
          </span>
        )}
      </div>
    </div>
  );
}





  // --------------------------------------------------------------------------
  // cellDoubleClick => create new shift
  // --------------------------------------------------------------------------
  function handleCellDoubleClick(args) {
    args.cancel = true;
    if (scheduleRef.current?.currentView==='Month') {
      // new shift for the active director
      const day = new Date(args.startTime); 
      day.setHours(9,0,0,0);
      const dayEnd = new Date(day);
      dayEnd.setHours(17,0,0,0);

      const newEv = {
        Id: null,
        SupervisorId: myDirectorResourceId,
        Subject: '',
        startTime: day,
        endTime: dayEnd,
        Description: '',
        storeOrEvent:'store-visits',
        storeIds: [],
        notes: []
      };
      setDirectorRowEvent(newEv);
      setIsLegacyDirectorModalOpen(true);
      return;
    }

    // If TimelineWeek => figure out which resource row
    const groupIndex = args.groupIndex;
    if (groupIndex==null) {
      alert('Invalid resource row => read-only');
      return;
    }

    const cellResource = scheduleRef.current.getResourcesByIndex(groupIndex);
    if (!cellResource || !cellResource.resourceData) {
      alert('Unknown resource => read-only');
      return;
    }

    const supId = cellResource.resourceData.id;
    const day = new Date(args.startTime);
    day.setHours(9,0,0,0);
    const dayEnd = new Date(day);
    dayEnd.setHours(17,0,0,0);

    const newEv2 = {
      Id:null,
      SupervisorId: supId,
      Subject:'',
      startTime:day,
      endTime:dayEnd,
      Description:'',
      storeOrEvent:'store-visits',
      storeIds:[],
      notes:[]
    };




    if (supId===myDirectorResourceId) {
      setDirectorRowEvent(newEv2);
      setIsLegacyDirectorModalOpen(true);
    } else if (directorSupId && supId===directorSupId) {
      setSupervisorRowEvent(newEv2);
      setIsSupModalOpen(true);
    } else {
      alert('Read-only row. No new shift allowed here.');
    }
  }

  // --------------------------------------------------------------------------
  // eventDoubleClick => edit shift
  // --------------------------------------------------------------------------
  function handleEventDoubleClick(args) {
    args.cancel = true;
    const ev = args.event || {};
    const supId = ev.SupervisorId;
    if (!supId) return;

    if (supId===myDirectorResourceId) {
      setDirectorRowEvent(ev);
      setIsLegacyDirectorModalOpen(true);
    } else if (directorSupId && supId===directorSupId) {
      setSupervisorRowEvent(ev);
      setIsSupModalOpen(true);
    } else {
      alert('Read-only shift => cannot edit other directors or other supervisors.');
    }
  }

  // --------------------------------------------------------------------------
  // DIRECTOR MODAL => save
  // --------------------------------------------------------------------------
  async function handleDirectorModalSave(modEv) {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/saveDirectorEvent`;

      // Convert times
      const payload = {
        ...modEv,
        startTime: formatLocalDateTime(new Date(modEv.startTime)),
        endTime:   formatLocalDateTime(new Date(modEv.endTime))
      };

      let subjectVal = 'Misc';
      if (modEv.storeOrEvent==='store-visits') {
        const areasJoined = (modEv.storeIds||[]).join(', ');
        subjectVal = `${directorName} - ${areasJoined}`;
      } else if (modEv.storeOrEvent?.startsWith('static-')) {
        subjectVal = modEv.storeOrEvent.substring(7);
      }

      const { data } = await axios.post(url, payload, { withCredentials:true });
      console.log('Saved director event =>', data);

      let finalId = modEv.Id;
      if (data.insertedId) {
        finalId = data.insertedId;
      } else if (data.eventId) {
        finalId = data.eventId;
      }

      if (finalId) {
        const newEvent = {
          Id: finalId,
          SupervisorId: myDirectorResourceId,
          Subject: subjectVal,
          startTime: modEv.startTime,
          endTime:   modEv.endTime,
          Description:'',
          storeOrEvent: modEv.storeOrEvent,
          storeIds:     modEv.storeIds || [],
          notes:        modEv.notes || []
        };

        if (modEv.Id) {
          setEvents(prev => prev.map(e => e.Id===modEv.Id ? newEvent : e));
        } else {
          setEvents(prev => [...prev, newEvent]);
        }
      }

      setIsLegacyDirectorModalOpen(false);
      if (onEventsChanged) await onEventsChanged();
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    } catch (err) {
      console.error('Error saving director event =>', err);
      alert('Error saving director event. Check console.');
    }
  }

  // --------------------------------------------------------------------------
  // DIRECTOR MODAL => delete
  // --------------------------------------------------------------------------
  async function handleDirectorModalDelete(id) {
    try {
      const baseUrl = getBaseUrl();
      const delUrl  = `${baseUrl}/pfield_events/pfield_director/directorEvent/${id}`;
      await axios.delete(delUrl, { withCredentials:true });
      console.log('Deleted director event =>', id);

      setEvents(prev => prev.filter(e => e.Id!==id));
      setIsLegacyDirectorModalOpen(false);
      if (onEventsChanged) await onEventsChanged();
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    } catch (err) {
      console.error('Error deleting director event =>', err);
      alert('Error deleting director event.');
    }
  }

  // --------------------------------------------------------------------------
  // DIRECTOR’s SUPERVISOR MODAL => save
  // --------------------------------------------------------------------------
  async function handleSupervisorModalSave(modEv) {
    // DB update is in DirectorCustomModal => we just update local
    if (modEv.Id) {
      setEvents(prev => prev.map(e => (e.Id===modEv.Id ? {...e,...modEv} : e)));
    } else {
      if (!modEv.Id) {
        modEv.Id = Math.floor(Math.random()*100000)+100;
      }
      setEvents(prev => [...prev, modEv]);
    }
    setIsSupModalOpen(false);
    if (onEventsChanged) await onEventsChanged();
    scheduleRef.current?.refreshEvents();
    scheduleRef.current?.dataBind();
  }

  // --------------------------------------------------------------------------
  // DIRECTOR’s SUPERVISOR MODAL => delete
  // --------------------------------------------------------------------------
  async function handleSupervisorModalDelete(id) {
    // DB delete is in DirectorCustomModal => just remove from local
    setEvents(prev => prev.filter(e => e.Id!==id));
    setIsSupModalOpen(false);
    if (onEventsChanged) await onEventsChanged();
    scheduleRef.current?.refreshEvents();
    scheduleRef.current?.dataBind();
  }

  // --------------------------------------------------------------------------
  // DRAG & RESIZE => store original times
  // --------------------------------------------------------------------------
  function handleDragStart(args) {
    if (scheduleRef.current?.currentView==='TimelineWeek') {
      const ev = Array.isArray(args.data) ? args.data[0] : args.data;
      if (ev?.Id) {
        if (ev.SupervisorId===myDirectorResourceId || (directorSupId && ev.SupervisorId===directorSupId)) {
          originalTimesRef.current[ev.Id] = {
            start:new Date(ev.startTime),
            end:new Date(ev.endTime)
          };
        }
      }
    }
  }
  function handleResizeStart(args) {
    handleDragStart(args);
  }

  // --------------------------------------------------------------------------
  // DRAG => save director shift
  // --------------------------------------------------------------------------
  async function saveDirectorShiftViaDrag(ev) {
    try {
      const current = events.find(e => e.Id===ev.Id) || {};
      const storeOrEvent = ev.storeOrEvent || current.storeOrEvent || 'static-Office';

      let storeIds = [];
      if (ev.storeIds?.length>0) {
        storeIds = ev.storeIds;
      } else if (current.storeIds?.length>0) {
        storeIds = current.storeIds;
      } else if (storeOrEvent==='store-visits') {
        const subj = ev.Subject || current.Subject || '';
        const dashIndex = subj.indexOf('-');
        if (dashIndex!==-1) {
          const areas = subj.substring(dashIndex+1).trim();
          storeIds = areas.split(',').map(s=>s.trim()).filter(Boolean);
        }
      }
      const notes = (ev.notes!==undefined ? ev.notes : current.notes) || [];

      const payload = {
        Id: ev.Id,
        startTime: formatLocalDateTime(new Date(ev.startTime)),
        endTime:   formatLocalDateTime(new Date(ev.endTime)),
        storeOrEvent,
        storeIds,
        notes
      };

      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/saveDirectorEvent`;
      const { data } = await axios.post(url, payload, { withCredentials:true });
      console.log('Director shift updated after drag =>', data);

      setEvents(prev => prev.map(e => {
        if (e.Id===ev.Id) {
          return { 
            ...e,
            startTime: ev.startTime,
            endTime:   ev.endTime,
            storeOrEvent,
            storeIds,
            notes
          };
        }
        return e;
      }));
    } catch (err) {
      console.error('Error updating director shift =>', err);
      alert('Error updating director shift.');
    }
  }

  // --------------------------------------------------------------------------
  // DRAG => save director’s SUPERVISOR shift
  // --------------------------------------------------------------------------
  async function saveDirectorSupShiftViaDrag(ev) {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/supervisorEvent`;

      const payload = {
        Id: ev.Id,
        startTime: formatLocalDateTime(new Date(ev.startTime)),
        endTime:   formatLocalDateTime(new Date(ev.endTime)),
        Subject:   ev.Subject || '',
        storeOrEvent: ev.storeOrEvent || '',
        storeIds:  ev.storeIds || [],
        notes:     ev.notes || []
      };

      const { data } = await axios.post(url, payload, { withCredentials:true });
      console.log('Supervisor shift updated =>', data);

      setEvents(prev => prev.map(e => {
        if (e.Id===ev.Id) {
          return { ...e, startTime:ev.startTime, endTime:ev.endTime };
        }
        return e;
      }));
    } catch (err) {
      console.error('Error updating supervisor shift =>', err);
      alert('Error updating supervisor shift.');
    }
  }

  // --------------------------------------------------------------------------
  // onActionBegin => handle create/change/remove events
  // --------------------------------------------------------------------------
  async function onActionBegin(args) {
    const rt = args.requestType;
    if (['eventCreate','eventChange','eventRemove'].includes(rt)) {
      const changedList = Array.isArray(args.data) ? args.data : [args.data];
      // skip if resource row is NOT the active director or the directorSupId
      for (const ev of changedList) {
        if (ev.SupervisorId!==myDirectorResourceId && ev.SupervisorId!==directorSupId) {
          args.cancel = true;
          return;
        }
      }

      // If create/change => validate
      if (rt==='eventCreate' || rt==='eventChange') {
        const { cancel, message } = validateShift(args, events, scheduleRef);
        if (cancel) {
          args.cancel = true;
          if (message) alert(message);
          return;
        }
      }
    }

    if (rt==='eventChange') {
      args.cancel=true;
      const changedList = Array.isArray(args.data)? args.data : [args.data];

      for (let ev of changedList) {
        if (scheduleRef.current?.currentView==='TimelineWeek') {
          const orig = originalTimesRef.current[ev.Id];
          if (orig) {
            const newStart = new Date(ev.startTime);
            const newEnd   = new Date(ev.endTime);
            // For non-director => revert if multi-day
            if (ev.SupervisorId!==myDirectorResourceId && !areDatesOnSameDay(newStart,newEnd)) {
              ev.startTime = new Date(orig.start);
              ev.endTime   = new Date(orig.end);
            }
          }
        }

        if (ev.SupervisorId===myDirectorResourceId) {
          await saveDirectorShiftViaDrag(ev);
        } else if (directorSupId && ev.SupervisorId===directorSupId) {
          await saveDirectorSupShiftViaDrag(ev);
        }
      }

      if (onEventsChanged) await onEventsChanged();
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    }

    if (rt==='eventRemove') {
      args.cancel=true;
      const removedList = Array.isArray(args.data)? args.data : [args.data];
      for (let ev of removedList) {
        if (ev.SupervisorId===myDirectorResourceId) {
          await deleteDirectorShift(ev.Id);
        } else if (directorSupId && ev.SupervisorId===directorSupId) {
          await deleteSupervisorShiftInDb(ev.Id);
        }
      }

      if (onEventsChanged) await onEventsChanged();
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    }
  }

  // --------------------------------------------------------------------------
  // onActionComplete => after the view or date changes
  // --------------------------------------------------------------------------
  async function onActionComplete(args) {
    if (args.requestType==='viewNavigate' || args.requestType==='dateNavigate') {
      setTimeout(async () => {
        if (!scheduleRef.current) return;

      // We only want to do the extended date range if the current view is Month
      if (scheduleRef.current.currentView === 'Month') {
        const vDates = scheduleRef.current.getCurrentViewDates();
        if (vDates && vDates.length > 0) {
          const earliest = new Date(vDates[0]);
          earliest.setHours(0, 0, 0, 0);

          const latest = new Date(vDates[vDates.length - 1]);
          latest.setHours(23, 59, 59, 999);

          // Convert them to strings if needed and pass back up
          onDateRangeChange?.(
            formatLocalDateTime(earliest),
            formatLocalDateTime(latest)
          );
        }
      } else if (scheduleRef.current.currentView==='TimelineWeek') {
          // if timeline => getCurrentViewDates
          const vDates = scheduleRef.current.getCurrentViewDates();
          if (vDates?.length>0) {
            const earliest = new Date(vDates[0]); earliest.setHours(0,0,0,0);
            const latest = new Date(vDates[vDates.length-1]); latest.setHours(23,59,59,999);

            onDateRangeChange?.(
              formatLocalDateTime(earliest),
              formatLocalDateTime(latest)
            );
            await fetchCompletedWeeks(
              formatLocalDateTime(earliest),
              formatLocalDateTime(latest)
            );
          }
        }

        setSelectedDate(scheduleRef.current.selectedDate);
      },0);
    }
  }

  function onDataBound() {
    if (overlayRef.current?.recalcPositions) {
      overlayRef.current.recalcPositions();
    }
  }

  function onPopupOpen(args) {
    if (args.type==='Editor' || args.type==='QuickInfo') {
      args.cancel = true;
    }
  }

function onEventRendered(args) {
  // The event’s resource row ID:
  const supId = args.data.SupervisorId;

  // Find the matching resource in supervisorResource:
  const foundResource = (supervisorResource || []).find(r => r.id === supId);

  // If it’s a director row, apply the shimmering gold CSS:
  if (foundResource?.isDirector) {
    args.element.classList.add('director-event');
    // Optionally remove any other background styling:
    args.element.style.backgroundColor = '';
  } else {
    // For supervisors, remove the shimmer class if present and apply their color
    args.element.classList.remove('director-event');
    if (foundResource) {
      args.element.style.backgroundColor = foundResource.color;
    }
  }
}

function shiftDurationTemplate(props) {
  // Convert start/end to Dates for time display (if you still want times in Month view)
  const sDate = new Date(props.startTime);
  const eDate = new Date(props.endTime);

  // Figure out whether we are in Month or TimelineWeek:
  const currentView = scheduleRef.current?.currentView;

  // Grab the resource row data:
  const supId = props.SupervisorId;
  const foundResource = (supervisorResource || []).find(r => r.id === supId);
  const resourceName = foundResource ? foundResource.text : 'Unknown';

  let displayedSubject = props.Subject || '';

  // If we are in Month view, we only want to show store name (the part before " - ")
  // and prefix it with the Supervisor/Director’s name:
  if (currentView === 'Month') {
    // The event’s Subject from the DB is typically "STORE NAME - Some Reason"
    // so parse out the store name part:
    let storeName = displayedSubject;
    if (storeName.includes(' - ')) {
      // The string before " - " is the store
      storeName = storeName.split(' - ')[0].trim();
    }
    // Build the new subject => "SupervisorName - StoreName"
    displayedSubject = `${resourceName} - ${storeName}`;
  }

  // Show start/end times if desired:
  if (isNaN(sDate) || isNaN(eDate)) {
    return (
      <div style={{ padding: '2px' }}>
        <b>{displayedSubject}</b>
        <span style={{ marginLeft: '4px' }}>(Invalid date)</span>
      </div>
    );
  }

  const startStr = sDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const endStr   = eDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <div style={{ padding: '2px' }}>
      <div style={{ fontWeight: 'bold' }}>{displayedSubject}</div>
      <div>{startStr} - {endStr}</div>
    </div>
  );
}


  return (
    <div style={{ position:'relative', display:'block', maxHeight:'none' }}>
      <LegacyDirectorModal
        isOpen={isLegacyDirectorModalOpen}
        initialEvent={directorRowEvent}
        onClose={() => setIsLegacyDirectorModalOpen(false)}
        onSave={handleDirectorModalSave}
        onDelete={handleDirectorModalDelete}
        availableStores={directorAreas}
      />

      <DirectorCustomModal
        isOpen={isSupModalOpen}
        eventData={supervisorRowEvent}
        onClose={() => setIsSupModalOpen(false)}
        onSave={handleSupervisorModalSave}
        onDelete={handleSupervisorModalDelete}
        directorStores={directorStores}
      />
	  
	  {isSupervisorNotesModalOpen && (
		  <SupervisorWeeklyStoreNotesModal
			isOpen={isSupervisorNotesModalOpen}
			onClose={() => setIsSupervisorNotesModalOpen(false)}
			supervisors={supervisorResource}
			initialSupervisorId={selectedSupervisorId}
		  />
		)}

      <ScheduleComponent
        ref={scheduleRef}
        rowAutoHeight
        showQuickInfo
        dragStart={handleDragStart}
        resizeStart={handleResizeStart}
        cellDoubleClick={handleCellDoubleClick}
        eventDoubleClick={handleEventDoubleClick}
        actionBegin={onActionBegin}
        actionComplete={onActionComplete}
        popupOpen={onPopupOpen}
        eventRendered={onEventRendered}
        dataBound={onDataBound}
        selectedDate={selectedDate}
        firstDayOfWeek={1}
        currentView="Month"
        height="auto"
        width="100%"
        resourceHeaderTemplate={resourceHeaderTemplate}
        eventSettings={{
		  allowDeleting: true,
          dataSource: events,
          template: shiftDurationTemplate,
          fields: {
            id:'Id',
            subject:{ name:'Subject' },
            groupID:{ name:'SupervisorId' },
            startTime:{ name:'startTime' },
            endTime:{ name:'endTime' },
            description:{ name:'Description' },
            storeIds:{ name:'storeIds' },
            storeOrEvent:{ name:'storeOrEvent' },
            notes:{ name:'notes' }
          }
        }}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="SupervisorId"
            title="Supervisor / Director"
            name="Supervisor"
            dataSource={supervisorResource}
            textField="text"
            idField="id"
            colorField="color"
            allowMultiple={false}
            expanded
          />
        </ResourcesDirective>

        <ViewsDirective>
          <ViewDirective
            option="TimelineWeek"
            startHour="00:00"
            endHour="24:00"
            timeScale={{ enable:false }}
            group={{ resources:['Supervisor'] }}
            eventSettings={{
              enableTooltip:false,
              tooltipTemplate:ShiftTooltip
            }}
          />
          <ViewDirective
            option="Month"
            rowAutoHeight
            group={{ resources:[] }}
            eventSettings={{
              enableTooltip:true,
              tooltipTemplate:ShiftTooltip
            }}
          />
        </ViewsDirective>
        <Inject services={[TimelineViews, Month, DragAndDrop, Resize]} />
      </ScheduleComponent>

<SupervisorHoursOverlay
  ref={overlayRef}
  supervisorHoursMap={supervisorHoursMap}
  supervisorResource={supervisorResource}
/>

    </div>
  );
});

export default DirectorScheduleSection;
