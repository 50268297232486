// FILE: src/components/ProlifiField/ProlifiFieldAnnouncementModal.jsx

import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  Button,
} from '@chakra-ui/react';

// 1) Set the date when the message should expire
const EXPIRE_DATE = new Date('2025-03-01T00:00:00');

// 2) The unique ID for this specific announcement
const MESSAGE_ID = '100000002';

// 3) Transform the announcement text into Chakra components
const ANNOUNCEMENT_MESSAGE = (
  <Box>
    <Text as="h2" fontSize="xl" fontWeight="bold" mb={4} color="gray.700">
      Esch Group Directors and Testing Supervisors!
    </Text>
    <Text mb={4} color="gray.600" lineHeight="taller">
      We wanted to extend our sincerest gratitude for all of your generous
      feedback so far and look forward to more as we fine tune a fully
      customized system for you and your teams!
    </Text>
    <Text color="gray.600" lineHeight="taller">
      Thank you,
      <br />
      ProlifiTech Incorporated
    </Text>
  </Box>
);

// Combine the date and messageID into one localStorage key
const localStorageKey = `ProlifiFieldAnnouncement_${MESSAGE_ID}_${EXPIRE_DATE
  .toISOString()
  .substring(0, 10)}`;

export default function ProlifiFieldAnnouncementModal({ onDismiss }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const now = new Date();

    // If current date is before EXPIRE_DATE, check if user has seen it
    if (now < EXPIRE_DATE) {
      const hasSeen = localStorage.getItem(localStorageKey);
      if (!hasSeen) {
        setIsOpen(true);
      } else {
        // If user already dismissed it, call onDismiss right away
        onDismiss?.();
      }
    } else {
      // If it’s expired, call onDismiss right away
      onDismiss?.();
    }
  }, [onDismiss]);

  const handleClose = () => {
    // Mark this message as seen in localStorage
    localStorage.setItem(localStorageKey, 'true');
    setIsOpen(false);

    // Signal to the parent that the announcement is dismissed
    onDismiss?.();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} zIndex={9999999} isCentered>
      <ModalOverlay />
      <ModalContent
        // Make the modal a bit wider:
        maxW="700px"
        w="90%"
        borderRadius="md"
        boxShadow="2xl"
      >
        {/* A modern, subtle gradient for the header text */}
        <ModalHeader
          bgGradient="linear(to-r, teal.500, teal.800)"
          bgClip="text"
          fontSize="2xl"
          fontWeight="extrabold"
          textAlign="center"
        >
          Announcement
        </ModalHeader>
        <ModalCloseButton />
        {/* The body uses our styled Chakra UI message */}
        <ModalBody>{ANNOUNCEMENT_MESSAGE}</ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" onClick={handleClose}>
            Got It
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
