// FILE: src/components/ProlifiField/Schedules/DirectorSchedulerContainer.jsx
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import DirectorDashboardSection from './dDashboardSec'; // optional collapse bar
import DirectorScheduleSection from './DirectorScheduleSection';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import './MyScheduler.css';

/**
 * The main container that fetches and stores:
 *  - supervisor shifts
 *  - all directors’ shifts
 *  - merges them into one set of events + resource rows
 *    in a custom order: 
 *     1) Active director row,
 *     2) That director’s supervisors,
 *     3) Next director row,
 *     4) That director’s supervisors,
 *     5) etc.
 */
export default function DirectorSchedulerContainer() {
  const [dashboardCollapsed, setDashboardCollapsed] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [supervisorResource, setSupervisorResource] = useState([]);
  const [directorStoreData, setDirectorStoreData] = useState([]);
  const [directorSupId, setDirectorSupId] = useState(null);
  const [directorDisplayName, setDirectorDisplayName] = useState('Unknown Director');
  const [activeDirResId, setActiveDirResId] = useState(100000);
  const scheduleSectionRef = useRef(null);

useEffect(() => {
  // Only set them once, when they are still null
  if (!startDate && !endDate) {
    const now = new Date();

    // 1) Calculate the first "official" day of this month
    const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);

    // 2) Calculate the last "official" day of this month
    const lastOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);

    // 3) Shift `firstOfMonth` *backwards* until it's a Monday (day=1)
    while (firstOfMonth.getDay() !== 1) {
      // Move back 1 day at a time
      firstOfMonth.setDate(firstOfMonth.getDate() - 1);
    }

    // 4) Shift `lastOfMonth` *forwards* until it's a Sunday (day=0)
    while (lastOfMonth.getDay() !== 0) {
      // Move forward 1 day at a time
      lastOfMonth.setDate(lastOfMonth.getDate() + 1);
    }

    setStartDate(firstOfMonth.toISOString());
    setEndDate(lastOfMonth.toISOString());
  }
}, [startDate, endDate]);


  useEffect(() => {
    if (startDate && endDate) {
      fetchDirectorShifts(startDate, endDate);
    }
  }, [startDate, endDate]);

  /**
   * buildResourceListWithDirectorGrouping
   *
   * Now receives activeDirectorId so that:
   * - For the active director, the resource row id is set to 100000.
   * - For other directors, the resource row id is set to 100000 + directorId.
   * Each resource row also gets an isDirector flag.
   */
  function buildResourceListWithDirectorGrouping(allDirectorsShifts, supervisors, activeDirectorId) {
    // Process directors
    const directorColorPalette = [
      '#00C853', '#AA00FF', '#FFD600', '#F44336',
      '#1E88E5', '#8BC34A', '#FF9100', '#880E4F'
    ];
    let dColorIndex = 0;
    const directorResourceMap = new Map();
    allDirectorsShifts.forEach(dObj => {
      const { directorId, directorName } = dObj;
      // Use activeDirectorId to determine the resource ID:
      const resourceId = (directorId === activeDirectorId ? 100000 : 100000 + directorId);
      const color = directorColorPalette[dColorIndex % directorColorPalette.length];
      dColorIndex++;
      directorResourceMap.set(directorId, {
        resourceId,
        text: directorName,
        color
      });
    });

    // Process supervisors
    const supColorPalette = [
      '#2196f3', '#FF9800', '#4CAF50', '#9C27B0', '#f44336',
      '#795548', '#E91E63', '#3F51B5', '#009688', '#CDDC39'
    ];
    let supColorIndex = 0;
    const supMap = new Map();
    supervisors.forEach(sup => {
      const color = supColorPalette[supColorIndex % supColorPalette.length];
      supColorIndex++;
      supMap.set(sup.supId, {
        id: sup.supId,
        text: sup.name,
        color,
        directors: sup.directors || [],
        isDirector: false
      });
    });

    // Build final resource array
    const finalResourceList = [];
    const directorIdOrder = allDirectorsShifts.map(d => d.directorId);
    const assignedSupIds = new Set();

    directorIdOrder.forEach(dirId => {
      const dirRes = directorResourceMap.get(dirId);
      if (!dirRes) return;
      finalResourceList.push({
        id: dirRes.resourceId,
        text: dirRes.text,
        color: dirRes.color,
        isDirector: true
      });
      supervisors.forEach(sup => {
        if (!assignedSupIds.has(sup.supId)) {
          const supData = supMap.get(sup.supId);
          if (!supData) return;
          const supFirstDir = (supData.directors[0] || {}).directorId || null;
          if (supFirstDir === dirId) {
            assignedSupIds.add(sup.supId);
            finalResourceList.push({
              id: supData.id,
              text: supData.text,
              color: supData.color,
              isDirector: false
            });
          }
        }
      });
    });

    supervisors.forEach(sup => {
      if (!assignedSupIds.has(sup.supId)) {
        const supData = supMap.get(sup.supId);
        if (supData) {
          finalResourceList.push({
            id: supData.id,
            text: supData.text,
            color: supData.color,
            isDirector: false
          });
        }
      }
    });

    return finalResourceList;
  }

  /**
   * fetchDirectorShifts
   *
   * Now updates director events to assign SupervisorId to match the resource row id.
   */
  async function fetchDirectorShifts(start, end) {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/director_shifts`;
      const payload = { StartDate: start, EndDate: end };
      console.log('[fetchDirectorShifts] => posting:', payload);

      const res = await axios.post(url, payload, { withCredentials: true });
      const data = res.data || {};
      console.log('[fetchDirectorShifts] => response:', data);

      const {
        supervisors = [],
        directorShifts = [],
        directorName = 'Unknown Director',
        directorIsSupervisor = false,
        directorAreas = [],
        allDirectorsShifts = [],
        activeDirectorId = 0
      } = data;

      setDirectorStoreData(directorAreas);
      let finalDirectorName = directorName;
      if (directorIsSupervisor) {
        finalDirectorName += ' (Director)';
      }
      setDirectorDisplayName(finalDirectorName);

      const combinedEvents = [];

      supervisors.forEach(sup => {
        (sup.shifts || []).forEach(sh => {
          combinedEvents.push({
            Id: sh.Id,
            SupervisorId: sup.supId,
            Subject: sh.Subject,
            startTime: sh.StartTime,
            endTime: sh.EndTime,
            Description: sh.Description,
            CombinedId: sh.CombinedId,
            CategoryId: sh.CategoryId
          });
        });
      });

      // Update director events to use the matching positive resource ID:
      allDirectorsShifts.forEach(dObj => {
        const { directorId, events: dEvents } = dObj;
        dEvents.forEach(ev => {
          combinedEvents.push({
            Id: ev.Id,
            SupervisorId: (directorId === activeDirectorId ? 100000 : 100000 + directorId),
            Subject: ev.Subject,
            startTime: ev.startTime,
            endTime: ev.endTime,
            Description: ev.Description,
            storeOrEvent: ev.storeOrEvent,
            storeIds: ev.storeIds || [],
            notes: ev.notes || []
          });
        });
      });

      const finalResourceList = buildResourceListWithDirectorGrouping(allDirectorsShifts, supervisors, activeDirectorId);
	  
	  // By design, the active director row is always 100000:
	const activeDirectorResourceId = 100000;
    setActiveDirResId(activeDirectorResourceId);

      setEvents(combinedEvents);
      setSupervisorResource(finalResourceList);
	  console.log('[SUPERVISOR RESOURCE]: ', supervisorResource);

      if (directorIsSupervisor) {
        const match = supervisors.find(s => s.name === directorName);
        if (match) {
          setDirectorSupId(match.supId);
        }
      }

    } catch (err) {
      console.error('Error fetching director shift data =>', err);
      setEvents([]);
      setSupervisorResource([]);
      setDirectorStoreData([]);
    }
  }

  async function handleEventsChanged() {
    if (startDate && endDate) {
      await fetchDirectorShifts(startDate, endDate);
    }
  }

  function toggleDashboard() {
    setDashboardCollapsed(prev => !prev);
  }

  function expandCollapseAndSwitchView(newView) {
    const oldCollapsed = dashboardCollapsed;
    setDashboardCollapsed(false);
    requestAnimationFrame(() => {
      if (scheduleSectionRef.current?.switchView) {
        scheduleSectionRef.current.switchView(newView);
      }
      requestAnimationFrame(() => {
        setDashboardCollapsed(oldCollapsed);
      });
    });
  }

  function handleDateRangeChange(start, end) {
    setStartDate(start);
    setEndDate(end);
  }

  return (
    <div style={styles.pageContainer}>
      <div style={{ width: '100%', position: 'relative' }}>
        <DirectorDashboardSection collapsed={dashboardCollapsed} onToggle={toggleDashboard} />

        <DirectorScheduleSection
          ref={scheduleSectionRef}
          events={events}
          setEvents={setEvents}
          supervisorResource={supervisorResource}
          onDateRangeChange={handleDateRangeChange}
          expandCollapseAndSwitchView={expandCollapseAndSwitchView}
          startDate={startDate}
          endDate={endDate}
          onEventsChanged={handleEventsChanged}
          directorStores={directorStoreData}
          directorSupId={directorSupId}
          directorAreas={directorStoreData}
          directorName={directorDisplayName}
		  myDirectorResourceId={activeDirResId}
        />
      </div>
    </div>
  );
}

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 1,
    padding: 1,
  }
};
