/**
 * File: src/components/CustomModal.jsx
 *
 * We ensure that every shift has a valid Subject:
 *    "<StoreName> - reason1, reason2, reason3"
 *
 * If the user selects "Other", we replace that with whatever
 * they typed in `ReasonOther`.
 *
 * Now updated to separate the date from start/end times (with daily constraint).
 * And the date, start time, and end time are all on one line (single row).
 */

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';

/** Return array of reason strings for "store" vs "Office/Meetings" etc. */
function getReasonOptionsForCombinedId(combinedId) {
  if (!combinedId || combinedId === '-- Select an option --') {
    return [];
  }
  if (combinedId === 'Vacation') return [];
  if (combinedId === 'Office') {
    return [
      'End of Week',
      'Schedule Review',
      'Inventory Review',
      'End of Month',
      'Other'
    ];
  }
  if (combinedId === 'Meetings') {
    return [
      'GM/Area',
      'Supervisor',
      'Class: AMLC',
      'Class: EGMC',
      'Other'
    ];
  }
  // Otherwise => assume it's a "store" => multi reason
  return [
    'General Visit',
    'Struggling Sales, Profit, or KPIs',
    'Audit/Quarterly',
    'Working w/ MIT',
    'GM not Present',
	'HR',
    'Other',
  ];
}

/**
 * Helper: to parse a JS Date into separate date & time strings:
 * - dateStr => "YYYY-MM-DD"
 * - timeStr => "HH:MM:SS"
 */
function splitIntoDateAndTime(dateObj) {
  if (!(dateObj instanceof Date) || isNaN(dateObj)) {
    return { dateStr: '', timeStr: '' };
  }
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');

  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  return {
    dateStr: `${year}-${month}-${day}`,
    timeStr: `${hours}:${minutes}:${seconds}`
  };
}

/**
 * Combine a date string "YYYY-MM-DD" and a time string "HH:mm:ss"
 * into a full JS Date object. If invalid, returns `new Date('Invalid')`.
 */
function combineDateAndTime(dateStr, timeStr) {
  try {
    if (!dateStr || !timeStr) {
      return new Date('Invalid');
    }
    const [year, month, day] = dateStr.split('-').map(Number);
    let [hh, mm, ss] = timeStr.split(':');
    if (typeof ss === 'undefined') {
      // if user only has "HH:MM", default to "00"
      ss = '00';
    }
    const hours = parseInt(hh, 10);
    const mins = parseInt(mm, 10);
    const secs = parseInt(ss, 10);

    return new Date(year, month - 1, day, hours, mins, secs);
  } catch (err) {
    return new Date('Invalid');
  }
}

export default function CustomModal({
  isOpen,
  eventData,
  onClose,
  onSave,
  onDelete,
  combinedOptions = [],
  allEvents = []
}) {
  const [formData, setFormData] = useState({
    Id: null,
    Subject: '',
    // We'll store date/time as strings now
    SelectedDate: '',
    SelectedStartTime: '',
    SelectedEndTime: '',
    Description: '',
    CombinedId: '-- Select an option --',
    Reasons: [],
    ReasonOther: '',
  });

  // On mount or when eventData changes => parse subject => store + reasons
  useEffect(() => {
    if (!eventData) {
      setFormData({
        Id: null,
        Subject: '',
        SelectedDate: '',
        SelectedStartTime: '',
        SelectedEndTime: '',
        Description: '',
        CombinedId: '-- Select an option --',
        Reasons: [],
        ReasonOther: '',
      });
      return;
    }

    // Extract the store's reasons from "Subject"
    const oldSubject = eventData.Subject || '';
    let parsedReasons = [];
    if (oldSubject.includes(' - ')) {
      const idx = oldSubject.indexOf(' - ');
      const afterDash = oldSubject.substring(idx + 3).trim(); // e.g. "General Visit, HR"
      parsedReasons = afterDash
        .split(',')
        .map(r => r.trim())
        .filter(Boolean);
    }

    // Convert eventData.StartTime and eventData.EndTime to date/time strings
    const stDate = eventData.StartTime ? new Date(eventData.StartTime) : new Date();
    const etDate = eventData.EndTime ? new Date(eventData.EndTime) : new Date();

    const { dateStr: stDatePart, timeStr: stTimePart } = splitIntoDateAndTime(stDate);
    const { dateStr: etDatePart, timeStr: etTimePart } = splitIntoDateAndTime(etDate);

    // We'll prefer using the StartTime's date as the SelectedDate,
    // assuming we do not allow multi-day anyway.
    setFormData({
      Id: eventData.Id ?? null,
      Subject: oldSubject,
      SelectedDate: stDatePart,
      SelectedStartTime: stTimePart, // e.g. "09:00:00"
      SelectedEndTime: etTimePart,
      Description: eventData.Description ?? '',
      CombinedId: eventData.CombinedId ?? '-- Select an option --',
      Reasons: parsedReasons,
      ReasonOther: '',
    });
  }, [eventData]);

  // Re-check the array of possible reason strings
  const reasonStrings = getReasonOptionsForCombinedId(formData.CombinedId);
  const reasonOptions = reasonStrings.map(r => ({ value: r, label: r }));
  const isOtherSelected = formData.Reasons.includes('Other');

  function handleReasonsChange(selected) {
    // selected => array of { value, label }
    if (!selected) {
      setFormData(prev => ({ ...prev, Reasons: [] }));
      return;
    }
    const selectedReasons = selected.map(item => item.value);
    setFormData(prev => ({ ...prev, Reasons: selectedReasons }));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }

  function handleDateChange(e) {
    const newDate = e.target.value; // "YYYY-MM-DD"
    setFormData(prev => ({ ...prev, SelectedDate: newDate }));
  }

  function handleStartTimeChange(e) {
    const newStartTime = e.target.value; // "HH:MM[:SS]"
    setFormData(prev => ({ ...prev, SelectedStartTime: newStartTime }));
  }

  function handleEndTimeChange(e) {
    const newEndTime = e.target.value; // "HH:MM[:SS]"
    setFormData(prev => ({ ...prev, SelectedEndTime: newEndTime }));
  }

  function handleSave() {
    // 1) Ensure user selected a store
    if (formData.CombinedId === '-- Select an option --') {
      alert('Please select a store/event.');
      return;
    }

    // 2) If reason array is required => if reasonStrings is non-empty, must pick at least 1
    if (reasonStrings.length > 0 && formData.Reasons.length === 0) {
      alert('Please select one or more reasons.');
      return;
    }

    // 3) Verify we have a valid date
    if (!formData.SelectedDate) {
      alert('Please select a valid date.');
      return;
    }

    // 4) Combine date + start/end times into real JS Dates
    const startDateObj = combineDateAndTime(formData.SelectedDate, formData.SelectedStartTime);
    const endDateObj = combineDateAndTime(formData.SelectedDate, formData.SelectedEndTime);

    if (isNaN(startDateObj.getTime()) || isNaN(endDateObj.getTime())) {
      alert('Please enter valid start/end times.');
      return;
    }

    // 5) Enforce daily constraint => same date, no crossing midnight
    // Also ensure end time is strictly after start time
    if (endDateObj <= startDateObj) {
      alert('The end time must be later than the start time on the same day.');
      return;
    }

    // 6) If "Other" is selected, replace it with "ReasonOther"
    let finalReasons = [...formData.Reasons];
    if (isOtherSelected && formData.ReasonOther.trim()) {
      const idx = finalReasons.indexOf('Other');
      if (idx !== -1) {
        finalReasons[idx] = formData.ReasonOther.trim();
      }
    }
    const reasonsStr = finalReasons.join(', ');

    // 7) Build new subject => "StoreName - reason1, reason2"
    const storeOption = combinedOptions.find(o => o.id === formData.CombinedId);
    const storeName = storeOption ? storeOption.text : formData.CombinedId;
    let newSubject = storeName;
    if (reasonsStr) {
      newSubject += ' - ' + reasonsStr;
    }

    // 8) Pass final data to onSave
    onSave({
      ...formData,
      Subject: newSubject,
      StartTime: startDateObj,
      EndTime: endDateObj,
    });
  }

  function handleDeleteClick() {
    if (formData.Id != null) {
      onDelete(formData.Id);
    }
  }

  const customStyles = {
    content: {
      maxWidth: '700px',
      margin: 'auto',
      borderRadius: '10px',
      padding: '20px',
      border: '1px solid #ddd',
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
      height: 'auto',
      maxHeight: '45vh',
      overflow: 'auto'
    },
    overlay: {
      zIndex: 999999,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Custom Editor Modal"
      ariaHideApp={false}
      style={customStyles}
    >
      <h2 style={{ marginTop: 0, marginBottom: '1rem' }}>
        {formData.Id ? 'Edit Event' : 'Create Event'}
      </h2>

      {/* Store/Event dropdown */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Store/Event</label>
          <select
            name="CombinedId"
            value={formData.CombinedId}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          >
            <option value="-- Select an option --">-- Select an option --</option>
            {combinedOptions.map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.text}
              </option>
            ))}
          </select>
        </div>

        {/* Multi-select for reasons => react-select */}
        {reasonStrings.length > 0 && (
          <div style={{ flex: 1 }}>
            <label style={{ fontWeight: 'bold', display: 'block' }}>Reasons</label>
            <Select
              isMulti
              options={reasonOptions}
              value={reasonOptions.filter(opt => formData.Reasons.includes(opt.value))}
              onChange={handleReasonsChange}
              placeholder="Select reason(s)..."
            />
          </div>
        )}
      </div>

      {/* If "Other" => show input */}
      {isOtherSelected && (
        <div style={{ marginBottom: '1rem' }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Other Reason</label>
          <input
            type="text"
            name="ReasonOther"
            value={formData.ReasonOther}
            onChange={handleChange}
            placeholder="Type your custom reason..."
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
      )}

      {/* Date + Start/End times in one row */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        {/* Date */}
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Date</label>
          <input
            type="date"
            name="SelectedDate"
            value={formData.SelectedDate}
            onChange={handleDateChange}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
        {/* Start Time */}
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Start Time</label>
          <input
            type="time"
            name="SelectedStartTime"
            value={formData.SelectedStartTime}
            onChange={handleStartTimeChange}
            min="03:00:00"
            max="23:59:59"
            step="1"
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
        {/* End Time */}
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>End Time</label>
          <input
            type="time"
            name="SelectedEndTime"
            value={formData.SelectedEndTime}
            onChange={handleEndTimeChange}
            min="03:00:00"
            max="23:59:59"
            step="1"
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
      </div>

      {/* Description */}
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ fontWeight: 'bold', display: 'block' }}>Description</label>
        <textarea
          name="Description"
          rows={3}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc'
          }}
          placeholder="Notes or details..."
          value={formData.Description}
          onChange={handleChange}
        />
      </div>

      {/* Bottom buttons */}
      <div style={{ marginTop: '1.5rem', display: 'flex', gap: '1rem' }}>
        {formData.Id && (
          <button
            onClick={handleDeleteClick}
            style={{
              background: '#f44336',
              color: '#fff',
              padding: '8px 16px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Delete
          </button>
        )}
        <button
          onClick={handleSave}
          style={{
            background: '#2196f3',
            color: '#fff',
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Save
        </button>
        <button
          onClick={onClose}
          style={{
            background: '#bbb',
            color: '#333',
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
