// File: src/components/ProlifiField/Schedules/WeeklyRequirements.jsx

import React from 'react';

export default function WeeklyRequirements({
  // (B) We rename to "weeklyData" from the backend
  weeklyData = [],
  patternWarnings = [],
  startDate,
  endDate
}) {
  if (!startDate || !endDate) {
    return (
      <div style={styles.container}>
        <h4 style={styles.title}>Weekly Requirement Issues</h4>
        <p style={styles.warnText}>
          You are in Month view. Please switch to Day or Week view to see weekly requirements.
        </p>
      </div>
    );
  }

  const fails = [];

  // 1) Overall hours requirement (with the “fewer than 4 stores => 8 hrs/store” rule)
  const totalScheduled = weeklyData.reduce((acc, row) => acc + row.scheduled, 0);

  // We also need the store rows (excluding Office/Vacation) for store count
  const storeRows = weeklyData.filter(
    (row) => !['Office', 'Vacation'].includes(row.combined_id)
  );

  // Count unique store IDs/names to avoid double-counting
  const uniqueStoreIds = new Set(storeRows.map((row) => row.storeName || row.combined_id));
  const storeCount = uniqueStoreIds.size;

  // For 4+ stores => require 45 hours; otherwise, require (8 * storeCount)
  const requiredHours = storeCount >= 4 ? 45 : storeCount * 8;

  if (totalScheduled < requiredHours) {
    fails.push(`[WARNING] You are scheduled less than the required ${requiredHours} hours this week`);
  }

  // 2) Office ≤ 10% of store hours
  const storeTotal = storeRows.reduce((acc, row) => acc + row.scheduled, 0);
  const officeRow = weeklyData.find((r) => r.combined_id === 'Office');
  const officeHours = officeRow ? officeRow.scheduled : 0;

  if (storeTotal > 0 && officeHours > 0.1 * storeTotal) {
    fails.push('[WARNING] Your office hours are greater than 10% of your total store hours');
  }

  // 3) Each store >= 4 hrs
  for (let row of storeRows) {
    if (row.scheduled < 4) {
      const storeName = row.storeName || row.combined_id;
      fails.push(`[WARNING] ${storeName} is not scheduled at least 4 hours this week`);
    }
  }

  // 4) patternWarnings from your scheduling patterns if desired
  for (const w of patternWarnings) {
    fails.push(w.message);
  }

  if (fails.length === 0) {
    return (
      <div style={styles.container}>
        <h4 style={styles.title}>Weekly Requirement Issues</h4>
        <p style={styles.successText}>All weekly requirements met...</p>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h4 style={styles.title}>Weekly Requirement Issues</h4>
      <ul style={styles.list}>
        {fails.map((msg, idx) => {
          const { icon, color, text } = parseFailMessage(msg);
          return (
            <li key={idx} style={styles.listItem}>
              <span style={{ color }}>
                {icon} {text}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

/**
 * Helper to parse the “FAIL” or “WARNING” messages 
 * and assign an icon/color.
 */
function parseFailMessage(msg) {
  const WARNING_TAG = '[WARNING]';
  const CAUTION_TAG = '[CAUTION]';

  if (msg.startsWith(WARNING_TAG)) {
    const textWithoutTag = msg.replace(WARNING_TAG, '').trim();
    return {
      icon: <span style={{ fontSize: '1.3em', marginRight: '0.2em' }}>🚨</span>,
      color: 'crimson',
      text: textWithoutTag,
    };
  } else if (msg.startsWith(CAUTION_TAG)) {
    const textWithoutTag = msg.replace(CAUTION_TAG, '').trim();
    return {
      icon: <span style={{ fontSize: '1.3em', marginRight: '0.2em' }}>⚠️</span>,
      color: 'darkorange',
      text: textWithoutTag,
    };
  } else {
    return {
      icon: <span style={{ fontSize: '1.3em', marginRight: '0.2em' }}>❗</span>,
      color: 'black',
      text: msg,
    };
  }
}

const styles = {
  container: {
    marginBottom: '1rem',
    backgroundColor: '#f9f9f9',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '0.75rem',
  },
  warnText: {
    fontSize: '0.9rem',
    color: '#666',
    fontStyle: 'italic',
  },
  successText: {
    margin: 0,
    fontSize: '0.9rem',
    color: '#666',
    fontStyle: 'italic',
  },
  title: {
    margin: '0 0 0.5rem',
    fontSize: '1rem',
    color: '#333',
    fontWeight: 600,
  },
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    marginBottom: '0.25rem',
  },
};
