// File: src/components/ProlifiField/Schedules/super/EJSScheduler.jsx

import React, { forwardRef } from 'react';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
  Month,
  DragAndDrop,
  Resize,
  ResourcesDirective,
  ResourceDirective,
  Inject
  // IMPORTANT: Do NOT import { Toolbar } from '@syncfusion/ej2-react-schedule'
} from '@syncfusion/ej2-react-schedule';

const EJSScheduler = forwardRef((props, ref) => {
  const {
    // All the props you normally pass in:
    selectedDate,
    currentView,
    height,
    width,
    firstDayOfWeek,
    eventSettings,
    renderCell,
    eventRendered,
    cellClick,
    cellDoubleClick,
    eventDoubleClick,
    actionBegin,
    actionComplete,
    popupOpen,
    showQuickInfo,
    allowDragAndDrop,
    allowResizing,
    categoryData,
    combinedResource,
    resourceHeaderTemplate
  } = props;

  /**
   * 1) Custom toolbar item click handler.
   *    We'll detect clicks on "MarkWeekComplete".
   */
  function onToolbarClick(args) {
    // Syncfusion typically prefixes your custom item id with "Schedule_".
    // So { id: 'MarkWeekComplete' } => "Schedule_MarkWeekComplete"
    if (args.item?.id === 'Schedule_MarkWeekComplete') {
      alert('Mark Week as Complete clicked!');
      // TODO: implement your real logic here...
    }
  }

  /**
   * 2) Wrap the parent's actionBegin to cancel any resourceExpand/resourceCollapse
   *    if you don’t want expansions, etc.
   */
  function handleActionBegin(args) {
    // This ensures resources stay expanded (if that’s your preference).
    if (args.requestType === 'resourceExpand' || args.requestType === 'resourceCollapse') {
      args.cancel = true;
      console.log('[EJSScheduler] CANCEL resourceExpand/resourceCollapse =>', args);
    }
    if (typeof actionBegin === 'function') {
      actionBegin(args);
    }
  }

  return (
    <ScheduleComponent
      ref={ref}
      // 3) This is crucial: define EXACTLY the views you want.
      // If you want "TimelineDay", "TimelineWeek", and "Month" only, do this:
      views={['TimelineDay', 'TimelineWeek', 'Month']}

      // 4) Overriding the toolbar array:
      //    - If "Today" isn't included, it shouldn't appear.
      //    - We add a custom item with text + id for your "Mark Week" button.
      toolbar={[
        'TimelineDay',        // Built-in item
        'TimelineWeek',       // Built-in item
        'Month',              // Built-in item
        { text: 'Mark Week as Complete', id: 'MarkWeekComplete' }
      ]}
      toolbarClick={onToolbarClick}

      // The rest of your typical props:
      selectedDate={selectedDate}
      currentView={currentView}
      height={height}
      width={width}
      firstDayOfWeek={firstDayOfWeek}
      showQuickInfo={showQuickInfo}
      allowDragAndDrop={allowDragAndDrop}
      allowResizing={allowResizing}
      renderCell={renderCell}
      eventRendered={eventRendered}
      cellClick={cellClick}
      cellDoubleClick={cellDoubleClick}
      eventDoubleClick={eventDoubleClick}
      actionBegin={handleActionBegin}
      actionComplete={actionComplete}
      popupOpen={popupOpen}
      eventSettings={eventSettings}
	  showTimeIndicator={false}
    >
      <ResourcesDirective>
        <ResourceDirective
          field="CategoryId"
          title="Category"
          name="Category"
          dataSource={categoryData}
          textField="CategoryName"
          idField="CategoryId"
          allowMultiple={false}
          expanded
        />
        <ResourceDirective
          field="CombinedId"
          title="Items"
          name="Combined"
          dataSource={combinedResource}
          textField="text"
          idField="CombinedId"
          colorField="color"
          allowMultiple={false}
          expanded
          groupIDField="CategoryId"
          resourceHeaderTemplate={resourceHeaderTemplate}
        />
      </ResourcesDirective>

      <ViewsDirective>
        <ViewDirective
          option="TimelineDay"
          startHour="06:00"
          endHour="24:00"
          timeScale={{ interval: 60, slotCount: 1 }}
          showTimeIndicator={false}
          group={{
            resources: ['Category', 'Combined'],
            allowGroupExpand: false
          }}
          eventSettings={{
            enableTooltip: false,
            tooltipTemplate: eventSettings?.tooltipTemplate,
            overflowIndicator: 'None'
          }}
        />
        <ViewDirective
          option="TimelineWeek"
          startHour="06:00"
          endHour="24:00"
          timeScale={{ enable: false }}
          group={{
            resources: ['Category', 'Combined'],
            allowGroupExpand: false
          }}
          eventSettings={{
            enableTooltip: false,
            tooltipTemplate: eventSettings?.tooltipTemplate,
            overflowIndicator: 'None'
          }}
        />
        <ViewDirective
          option="Month"
          group={{ resources: [] }} // No grouping in Month
          eventSettings={{
            enableTooltip: true,
            tooltipTemplate: eventSettings?.tooltipTemplate,
            overflowIndicator: 'None'
          }}
        />
      </ViewsDirective>

      {/**
       * 5) NOTE: We do NOT inject 'Toolbar' as a separate service
       *    in newer (and many older) Syncfusion versions. The built-in
       *    toolbar is already included with the base package.
       */}
      <Inject services={[TimelineViews, Month, DragAndDrop, Resize]} />
    </ScheduleComponent>
  );
});

export default EJSScheduler;
