// File: src/components/ProlifiField/Schedules/wsn/director/ModalRequirementsSummary.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, VStack, HStack, Button, Collapse } from '@chakra-ui/react';
import { getWeeklyWarnings } from '../../../../utils/WeeklyRequirementsLogic';
import { getMonthlyWarnings } from '../../../../utils/MonthlyRequirementsWarnings';

export default function ModalRequirementsSummary({
  weeklyData = [],
  monthlyData = [],
  cycleLength = 3,
  patternWarnings = [],
  startDate,
  endDate,
  stores = [] // e.g. { store_id: "2410", store_name: "48TH STREET" }
}) {
  console.log('[WEEKLY DATA]: ', weeklyData);
  console.log('[MONTHLY DATA]: ', monthlyData);

  // Pre-build complete weekly data by ensuring each store is represented.
  const completeWeeklyData = stores.map(store => {
    const storeIdStr = store.store_id.toString().trim();
    const storeNameStr = store.store_name.toString().trim().toLowerCase();
    const matching = weeklyData.find(row => {
      const rowCombinedId = row.combined_id.toString().trim();
      const rowStoreName = row.storeName.toString().trim().toLowerCase();
      return rowCombinedId === storeIdStr || rowCombinedId === storeNameStr || rowStoreName === storeNameStr;
    });
    if (matching) return matching;
    // Default: no data, scheduled is 0.
    return {
      combined_id: store.store_id,
      storeName: store.store_name,
      scheduled: 0,
      required: 0,
      gap: 0,
    };
  });

  // Pre-build complete monthly data.
  const completeMonthlyData = stores
    .filter(store => store.store_id !== 'Office')
    .map(store => {
      const storeIdNorm = store.store_id.toString().trim().toLowerCase();
      const storeNameNorm = store.store_name.toString().trim().toLowerCase();
      const matching = monthlyData.find(row => {
        const combinedIdNorm = row.combined_id.toString().trim().toLowerCase();
        return combinedIdNorm === storeIdNorm || combinedIdNorm === storeNameNorm;
      });
      if (matching) return matching;
      return {
        combined_id: store.store_id,
        storeName: store.store_name,
        nightVisits: 0,
        after8Visits: 0,
        weekendVisits: 0,
        workingWithMITCount: 0,
        gmNotPresentCount: 0,
      };
    });

  // Get warnings.
  const weeklyWarnings = getWeeklyWarnings(weeklyData);
  const monthlyWarnings = getMonthlyWarnings(completeMonthlyData, cycleLength);
  const additionalWarnings = patternWarnings.map(w => w.message);

  console.log('[COMPLETE WEEKLY DATA]: ', completeWeeklyData);

  // Calculate total weekly scheduled hours.
  const totalWeeklyHours = weeklyData.reduce(
    (acc, entry) => acc + (entry.scheduled || 0),
    0
  );

  // Styles for the three columns.
  const columnStyle = { flex: 1, paddingX: 2 };

  // Local state for collapsing/expanding the summary.
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      p={4}
      mb={4}
      bg="gray.50"
      maxH="25vh"         // Adjust max height as needed.
      overflowY="auto"    // Enable vertical scrolling if content overflows.
    >
      {/* Full-width collapse/expand header */}
      <Box
        as="button"
        onClick={() => setIsCollapsed(!isCollapsed)}
        width="100%"
        borderBottom="1px solid #ddd"
        py={1}
        textAlign="center"
        fontSize="sm"
        fontWeight="semibold"
		mt={-10}
      >
        {isCollapsed ? 'Expand Requirements Summary' : 'Collapse Requirements Summary'}
      </Box>

      <Collapse in={!isCollapsed} animateOpacity>
        <HStack spacing={4} align="start" mt={1}>
          {/* Left Column: Weekly Requirements */}
          <Box {...columnStyle} borderRight="1px solid #ddd">
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Weekly Requirements
            </Text>
            {weeklyWarnings.length === 0 ? (
              <Text color="green.600" fontSize="sm">
                Weekly requirements met.
              </Text>
            ) : (
              <VStack align="start" spacing={1}>
                {weeklyWarnings.map((msg, idx) => (
                  <Text key={idx} fontSize="sm" color="red.600">
                    {msg.replace(/^\[(WARNING|CAUTION)\]\s*/, '')}
                  </Text>
                ))}
              </VStack>
            )}
          </Box>

          {/* Middle Column: Total Weekly Scheduled Hours */}
          <Box
            {...columnStyle}
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Text fontSize="lg" fontWeight="semibold" color="gray.800">
              Total Weekly Scheduled Hours: {totalWeeklyHours}
            </Text>
          </Box>

          {/* Right Column: Monthly Requirements */}
          <Box {...columnStyle}>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Monthly Requirements
            </Text>
            {monthlyWarnings.length === 0 ? (
              <Text color="green.600" fontSize="sm">
                Monthly requirements met.
              </Text>
            ) : (
              <VStack align="start" spacing={1}>
                {monthlyWarnings.map((msg, idx) => (
                  <Text key={idx} fontSize="sm" color="orange.600">
                    {msg.replace(/^\[(WARNING|CAUTION)\]\s*/, '')}
                  </Text>
                ))}
              </VStack>
            )}
            {additionalWarnings.length > 0 && (
              <VStack align="start" spacing={1} mt={2}>
                {additionalWarnings.map((msg, idx) => (
                  <Text key={idx} fontSize="sm" color="gray.700">
                    {msg}
                  </Text>
                ))}
              </VStack>
            )}
          </Box>
        </HStack>
      </Collapse>
    </Box>
  );
}

ModalRequirementsSummary.propTypes = {
  weeklyData: PropTypes.array,
  monthlyData: PropTypes.array,
  cycleLength: PropTypes.number,
  patternWarnings: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  stores: PropTypes.array,
};
