import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';

/**
 * This overlay draws the total hours for each "supervisor" row,
 * skipping any row that is marked as a director (i.e. resource.isDirector is true).
 *
 * For each resource in the supervisorResource array with isDirector false,
 * we search the DOM for elements with an aria-label beginning with the resource text.
 * If more than one element is found (as in the case for a director who is also a supervisor),
 * we assume the second element represents the supervisor row.
 */
function SupervisorHoursOverlay({ supervisorHoursMap = {}, supervisorResource = [] }, ref) {
  const [rowOverlays, setRowOverlays] = useState([]);
  const overlayRef = useRef(null);

  function recalcPositions() {
    console.log("recalcPositions called (new method)");
    const resourceContainer =
      document.querySelector('.e-resource-column-wrap') ||
      document.querySelector('.e-timeline-resource');
    if (!resourceContainer) {
      console.log("No resource container found.");
      setRowOverlays([]);
      return;
    }
    console.log("Resource container found:", resourceContainer);
    const containerRect = resourceContainer.getBoundingClientRect();
    const newOverlays = [];

    supervisorResource.forEach((resource) => {
      // Skip if resource is marked as a director
      if (resource.isDirector) {
        console.log(`Skipping resource "${resource.text}" because it is marked as a director.`);
        return;
      }
      // Build a selector to find DOM elements whose aria-label starts with the resource text
      const selector = `[aria-label^="${resource.text} resource"]`;
      const elems = Array.from(resourceContainer.querySelectorAll(selector));
      console.log(`Resource "${resource.text}" (id: ${resource.id}) - found ${elems.length} matching DOM elements.`);
      let targetElem = null;
      if (elems.length > 1) {
        // If more than one element is found, assume the second one is the supervisor row.
        targetElem = elems[1];
        console.log(`Resource "${resource.text}": using second element for supervisor row.`);
      } else if (elems.length === 1) {
        targetElem = elems[0];
        console.log(`Resource "${resource.text}": using the only found element.`);
      } else {
        console.log(`Resource "${resource.text}": no DOM element found; skipping.`);
        return;
      }
      const rowRect = targetElem.getBoundingClientRect();
      const offsetTop = (rowRect.top - containerRect.top) + 90;
      const offsetLeft = (rowRect.left - containerRect.left) + 6;
      const hoursValue = supervisorHoursMap[resource.id] ?? 0;
      console.log(`Resource "${resource.text}" (id: ${resource.id}) - Calculated offsets: top=${offsetTop}, left=${offsetLeft}, hours=${hoursValue}`);
      newOverlays.push({
        supId: resource.id,
        resourceText: resource.text,
        hours: hoursValue,
        top: offsetTop,
        left: offsetLeft
      });
      console.log(`Overlay added for "${resource.text}":`, newOverlays[newOverlays.length - 1]);
    });

    console.log("Final overlays array (new method):", newOverlays);
    setRowOverlays(newOverlays);
  }

  useImperativeHandle(ref, () => ({
    recalcPositions
  }));

  useEffect(() => {
    console.log("SupervisorHoursOverlay useEffect triggered (new method)");
    recalcPositions();
    window.addEventListener('resize', recalcPositions);

    const container =
      document.querySelector('.e-resource-column-wrap') ||
      document.querySelector('.e-timeline-resource');
    if (container) {
      container.addEventListener('scroll', recalcPositions, { passive: true });
    }

    return () => {
      window.removeEventListener('resize', recalcPositions);
      if (container) {
        container.removeEventListener('scroll', recalcPositions);
      }
    };
  }, [supervisorHoursMap, supervisorResource]);

  return (
    <div
      ref={overlayRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: 2000
      }}
    >
      {rowOverlays.map(o => {
        let textColor = '#333';
        let borderColor = '#ccc';
        let boxShadow = '0 1px 2px rgba(0,0,0,0.1)';

        if (o.hours < 45) {
          textColor = 'red';
          borderColor = 'red';
          boxShadow = '0 0 5px 2px rgba(255,0,0,0.3)';
        }
        return (
          <div
            key={o.supId}
            style={{
              position: 'absolute',
              top: o.top,
              left: o.left,
              backgroundColor: 'rgba(248,248,248,0.9)',
              color: textColor,
              fontSize: '0.6em',
              padding: '1.5px 4px',
              border: `1px solid ${borderColor}`,
              borderRadius: 2,
              boxShadow
            }}
          >
            {o.hours} hrs
          </div>
        );
      })}
    </div>
  );
}

export default forwardRef(SupervisorHoursOverlay);
