// BbUpload.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingIndicator from "../LoadingIndicator";

import {
  Flex,
  Box,
  Button,
  Text,
  useColorModeValue,
  Input,
} from "@chakra-ui/react";

import SalesDataChecker from "./SalesDataChecker";

// Helper function to show SweetAlert if incomplete
function showIncompleteModal(incompleteStores, MySwal) {
  if (!incompleteStores || incompleteStores.length === 0) return;

  // Build an HTML list of store names, each on its own line, centered
  const listItems = incompleteStores
    .map((store) => `<li>${store.store_name}</li>`)
    .join("");

  const storeListHtml = `
    <ul style="text-align:center; list-style:none; padding-left:0;">
      ${listItems}
    </ul>
  `;

  MySwal.fire({
    title: "Manual Data Incomplete",
    icon: "warning",
    html: `
      <p>You cannot upload your BlueBook file yet.</p>
      <p>Please complete the manual data for these store(s):</p>
      ${storeListHtml}
    `,
    showCancelButton: true,
    confirmButtonText: "Go to Weekly Manual",
    cancelButtonText: "Close",
  }).then((result) => {
    if (result.isConfirmed) {
      // Navigate to the Weekly Manual page
      window.location.href = "/w-manual";
    }
  });
}

function BbUpload() {
  const [file, setFile] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userStores, setUserStores] = useState(null);

  const MySwal = withReactContent(Swal);

  // Determine base URL for dev/prod
  const getBaseUrl = () => {
    return process.env.NODE_ENV === "development"
      ? "http://72.167.34.236:5000"
      : "https://prolifi.app";
  };

  // Fetch user stores
  useEffect(() => {
    async function fetchStores() {
      try {
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/bbUpload/getUserStores`, {
          withCredentials: true,
        });
        console.log("Stores returned from server:", response.data);
        setUserStores(response.data.stores);
      } catch (err) {
        console.error("Error retrieving user stores:", err);
      }
    }
    fetchStores();
  }, []);

  // Decide if we can upload
  let canUpload = false;
  let incompleteStores = []; // for the popup

  if (userStores === "Director") {
    // Directors: always can
    canUpload = true;
  } else if (Array.isArray(userStores) && userStores.length > 0) {
    // Check which are incomplete
    incompleteStores = userStores.filter((store) => store.hasEntryThisWeek !== true);
    // can upload only if none are incomplete
    canUpload = incompleteStores.length === 0;
  }

  // Toggle the visibility of the upload form
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  // Utility: confirm the file is .xlsx based on its name
  const isValidXlsxFile = (filename) => filename.toLowerCase().endsWith(".xlsx");

  // When the user selects a file via the input
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const chosenFile = e.target.files[0];
      if (!isValidXlsxFile(chosenFile.name)) {
        MySwal.fire({
          icon: "error",
          title: "Invalid file type",
          text: "Please select an .xlsx file.",
        });
        // Reset input so user can pick again
        e.target.value = null;
        return;
      }
      setFile(chosenFile);
    }
  };

  // Upload file to the server
  const handleUpload = async () => {
    if (!file) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a file first!",
      });
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(`${baseUrl}/api/bbUpload/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Upload successful", response.data);

      MySwal.fire({
        icon: "success",
        title: "Success!",
        text: "Data successfully inserted into database.",
      });
    } catch (error) {
      console.error("Upload failed", error);
      MySwal.fire({
        icon: "error",
        title: "Failed!",
        text: "Failed to upload data. Please try again.",
      });
    } finally {
      setIsLoading(false);
      setFile(null);
    }
  };

  // For styling
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dropBgHover = useColorModeValue("blue.50", "gray.600");
  const dropBorderColor = useColorModeValue("blue.300", "blue.600");

  // Handler for the label's onClick
  const handleUploadZoneClick = (e) => {
    if (!canUpload) {
      e.preventDefault();
      e.stopPropagation();
      // Show the "incomplete" modal
      showIncompleteModal(incompleteStores, MySwal);
    }
  };

  // Drag & drop handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!canUpload) {
      showIncompleteModal(incompleteStores, MySwal);
    } else {
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const droppedFile = e.dataTransfer.files[0];
        if (!isValidXlsxFile(droppedFile.name)) {
          MySwal.fire({
            icon: "error",
            title: "Invalid file type",
            text: "Please drop an .xlsx file.",
          });
        } else {
          setFile(droppedFile);
        }
      }
    }
  };

  return (
    <Flex direction="column" align="center" bg={bgColor} minH="100vh" p={6}>
      {/* Loading Overlay */}
      {isLoading && (
        <Flex
          position="fixed"
          top={0}
          left={0}
          w="100vw"
          h="100vh"
          bg="rgba(0,0,0,0.5)"
          align="center"
          justify="center"
          zIndex="9999"
        >
          <LoadingIndicator />
        </Flex>
      )}

      <Box
        width="100%"
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="md"
        bg={boxBgColor}
        mb={6}
        shadow="md"
      >
        {/* Thin toggle bar at the top */}
        <Box
          as="button"
          onClick={toggleFormVisibility}
          display="block"
          w="100%"
          borderBottomWidth={isFormVisible ? "1px" : "0"}
          borderColor={useColorModeValue("gray.200", "gray.600")}
          textAlign="center"
          py={1}
          fontSize="sm"
          bgGradient="linear(to-r, blue.400, blue.600)"
          color="white"
          _hover={{ bgGradient: "linear(to-r, blue.500, blue.700)" }}
          _focus={{ outline: "none" }}
        >
          {isFormVisible ? "▲ Hide Form" : "▼ Show Form"}
        </Box>

        {/* The form content, only if visible */}
        {isFormVisible && (
          <Box p={4}>
            {/* Upload zone (label for the hidden <Input>) */}
            <Box
              as="label"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={6}
              border="2px dashed"
              borderColor={dropBorderColor}
              borderRadius="md"
              cursor={canUpload ? "pointer" : "not-allowed"}
              transition="background 0.2s"
              _hover={{ bg: canUpload ? dropBgHover : "none" }}
              mb={4}
              opacity={canUpload ? 1 : 0.6}
              onClick={handleUploadZoneClick}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {!file ? (
                <Text fontSize="sm" color="gray.500">
                  {canUpload
                    ? "Click or drag your BlueBook (.xlsx) file here."
                    : "Manual data not completed yet."}
                </Text>
              ) : (
                <Text fontSize="sm" color="blue.500" fontWeight="medium">
                  Selected file: {file.name}
                </Text>
              )}
              <Input
                type="file"
                accept=".xlsx"  // Only allow .xlsx
                hidden
                onChange={handleFileChange}
                disabled={!canUpload}
              />
            </Box>

            {/* Upload Button */}
            <Flex justify="flex-end">
              <Button
                onClick={handleUpload}
                disabled={isLoading || !canUpload}
                bgGradient="linear(to-r, blue.400, blue.600)"
                _hover={{ bgGradient: "linear(to-r, blue.500, blue.700)" }}
                _active={{ bgGradient: "linear(to-r, blue.600, blue.800)" }}
                color="white"
                fontWeight="bold"
                borderRadius="md"
                boxShadow="md"
                transition="all 0.3s"
              >
                Upload
              </Button>
            </Flex>
          </Box>
        )}
      </Box>

      {/* SalesDataChecker */}
      <Box
        width="100%"
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="md"
        bg={boxBgColor}
        p={4}
        shadow="md"
      >
        <SalesDataChecker />
      </Box>
    </Flex>
  );
}

export default BbUpload;
