// WeeklyDataEntryForm.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  Collapse,
  useColorModeValue,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";
import { Link } from "react-router-dom"; // If you’re using React Router

import {
  format,
  startOfISOWeek,
  endOfISOWeek,
  getISOWeek,
  subDays,
  getMonth,
  getYear,
} from "date-fns";

function WeeklyDataEntryForm() {
  const [isFormVisible, setIsFormVisible] = useState(true);

  // If this is true, we block the entire weekly form.
  const [anyMissingMonthlyData, setAnyMissingMonthlyData] = useState(false);

  const [formData, setFormData] = useState({
    storeSelection: "-1",
    hoursEndingMonth: "",
    hoursBeginningMonth: "",
    foodVarianceWeek: "",
    foodVarianceMonth: "",
    entryDate: "",
  });

  const [dateRanges, setDateRanges] = useState({
    hoursEndMonthRange: "",
    hoursStartNextMonthRange: "",
    currentMonth: "",
  });

  const [popsFormData, setPopsFormData] = useState({
    mysteryShopScore: "0", // Hidden, but still included
    syncReports: "",
    actualFoodAndPaperCost: "",
  });

  // We'll store objects like:
  // [
  //   { store_id: 2410, store_name: 'Store ABC', hasMonthlyData: 1 },
  //   ...
  // ]
  const [supervisedStores, setSupervisedStores] = useState([]);

  // Theming / styling
  const panelBg = useColorModeValue("white", "gray.700");
  const borderCol = useColorModeValue("gray.200", "gray.600");
  const highlightColor = useColorModeValue("red.500", "red.300");

  // Toggle the form visibility
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return "https://prolifi.app";
    } else {
      return "http://localhost:5000";
    }
  };

  // On component mount, fetch supervised stores & set date ranges
  useEffect(() => {
    fetchMyStores();
    calculateDateRangesForISOWeek();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Whenever the store changes, re-fetch data from the server
  useEffect(() => {
    if (formData.storeSelection !== "-1") {
      // Clear out old data first
      setFormData((prev) => ({
        ...prev,
        hoursEndingMonth: "",
        hoursBeginningMonth: "",
        foodVarianceWeek: "",
        foodVarianceMonth: "",
        entryDate: "",
      }));
      setPopsFormData({
        mysteryShopScore: "0",
        syncReports: "",
        actualFoodAndPaperCost: "",
      });
      // Then fetch new data
      fetchExistingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.storeSelection]);

  // 1) GET /my-stores to retrieve the stores & store names for the current user
  //    We also check if any store is missing monthly data => block weekly form
  const fetchMyStores = async () => {
    const baseUrl = getBaseUrl();
    try {
      const response = await axios.get(
        `${baseUrl}/api/weekly-data-entry/my-stores`,
        { withCredentials: true }
      );
      console.log("My stores:", response.data); // For debugging
      setSupervisedStores(response.data);

      // If ANY store has hasMonthlyData === false (or 0),
      // we disable the weekly form for the entire user.
      const anyMissing = response.data.some(
        (storeObj) =>
          storeObj.hasMonthlyData === false || storeObj.hasMonthlyData === 0
      );
      setAnyMissingMonthlyData(anyMissing);
    } catch (error) {
      console.error("Error fetching user store list:", error);
    }
  };

  // 2) If user picks a store, fetch existing weekly/pops data for that store & ISO week
  const fetchExistingData = async () => {
    const baseUrl = getBaseUrl();
    const today = new Date();
    const isMondayTuesdayOrWednesday =
      today.getDay() === 1 || today.getDay() === 2 || today.getDay() === 3;
    const entryDate = isMondayTuesdayOrWednesday
      ? endOfISOWeek(subDays(today, 7))
      : endOfISOWeek(today);
    const weekNumber = `${entryDate.getFullYear()}${String(
      getISOWeek(entryDate)
    ).padStart(2, "0")}`;

    // 2a) Weekly data
    try {
      const response = await axios.get(`${baseUrl}/api/weekly-data-entry/fetch-data`, {
        params: {
          storeId: formData.storeSelection,
          weekNumber,
        },
      });
      if (response.data) {
        const fetchedData = response.data;
        const isSplitWeek =
          fetchedData.hours_ending_month !== null &&
          fetchedData.hours_beginning_month !== null;

        setFormData((prev) => ({
          ...prev,
          storeSelection: fetchedData.store_id.toString(),
          hoursEndingMonth: isSplitWeek
            ? fetchedData.hours_ending_month
            : fetchedData.hours_full_week || "",
          hoursBeginningMonth: isSplitWeek
            ? fetchedData.hours_beginning_month
            : "",
          foodVarianceWeek: fetchedData.food_variance_week || "",
          foodVarianceMonth: fetchedData.food_variance_month || "",
        }));
      }
    } catch (error) {
      console.error("Error fetching existing data:", error);
    }

    // 2b) PoPs data
    try {
      const popsResponse = await axios.get(
        `${baseUrl}/api/weekly-data-entry/fetch-pops-data`,
        {
          params: { storeId: formData.storeSelection },
        }
      );
      if (popsResponse.data) {
        const popsData = popsResponse.data;
        setPopsFormData({
          mysteryShopScore: popsData.mystery_shop_score || "0",
          syncReports: popsData.mtd_sync_reports || "",
          actualFoodAndPaperCost: popsData.actual_fandp_cost || "",
        });
      }
    } catch (error) {
      console.error("Error fetching PoPs data:", error);
    }
  };

  // 3) Calculate date ranges for ISO week so we can show placeholder text
  const calculateDateRangesForISOWeek = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    let weekStart, weekEnd;

    if (dayOfWeek === 1) {
      const lastMonday = subDays(currentDate, 7);
      weekStart = startOfISOWeek(lastMonday);
      weekEnd = endOfISOWeek(lastMonday);
    } else {
      weekStart = startOfISOWeek(currentDate);
      weekEnd = endOfISOWeek(currentDate);
    }

    const currentMonthFormatted = format(currentDate, "MMMM");
    const isSplitWeek = getMonth(weekStart) !== getMonth(weekEnd);

    let hoursEndMonthRangeFormatted = "";
    let hoursStartNextMonthRangeFormatted = "";

    if (isSplitWeek) {
      const lastDayOfStartMonth = new Date(
        getYear(weekStart),
        getMonth(weekStart) + 1,
        0
      );
      hoursEndMonthRangeFormatted = `${format(
        weekStart,
        "MM/dd"
      )} - ${format(lastDayOfStartMonth, "MM/dd")}`;

      const firstDayOfEndMonth = new Date(
        getYear(weekEnd),
        getMonth(weekEnd),
        1
      );
      hoursStartNextMonthRangeFormatted = `${format(
        firstDayOfEndMonth,
        "MM/dd"
      )} - ${format(weekEnd, "MM/dd")}`;
    } else {
      hoursEndMonthRangeFormatted = `${format(
        weekStart,
        "MM/dd"
      )} - ${format(weekEnd, "MM/dd")}`;
    }

    setDateRanges({
      currentMonth: currentMonthFormatted,
      hoursEndMonthRange: hoursEndMonthRangeFormatted,
      hoursStartNextMonthRange: hoursStartNextMonthRangeFormatted,
    });
  };

  // Handle normal form field changes
  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Handle PoPs form field changes
  const handlePopsChange = (e) => {
    const { name, value } = e.target;
    setPopsFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Validation logic in handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isSplitWeek = dateRanges.hoursStartNextMonthRange !== "";

    // 1) Store selection check
    if (formData.storeSelection === "-1") {
      alert("Please select a store before submitting");
      return;
    }

    // 2) Hours required
    if (!formData.hoursEndingMonth) {
      alert("Please enter Hours (Ending Month) before submitting");
      return;
    }
    // If it's a split week, also require hoursBeginningMonth
    if (isSplitWeek && !formData.hoursBeginningMonth) {
      alert("Please enter Hours (Beginning Next Month) before submitting");
      return;
    }

    // 3) Food variance required
    if (!formData.foodVarianceWeek) {
      alert("Please enter the Food Variance (Week) before submitting");
      return;
    }
    if (!formData.foodVarianceMonth) {
      alert("Please enter the Food Variance (Month) before submitting");
      return;
    }

    // 4) PoPs data required
    if (!popsFormData.syncReports) {
      alert("Please enter the Sync Reports (MTD) before submitting");
      return;
    }
    if (!popsFormData.actualFoodAndPaperCost) {
      alert("Please enter the Actual F&P (MTD) before submitting");
      return;
    }

    // Build final form object
    const { hoursBeginningMonth, ...restOfFormData } = formData;
    const combinedFormData = {
      ...restOfFormData,
      // If it's a split week, include hoursBeginningMonth
      ...(isSplitWeek ? { hoursBeginningMonth } : {}),
      // Always include PoPs fields now
      ...popsFormData,
    };

    console.log("Final form data being sent:", combinedFormData);

    const baseUrl = getBaseUrl();
    try {
      const response = await axios.post(
        `${baseUrl}/api/weekly-data-entry`,
        combinedFormData,
        { withCredentials: true }
      );
      console.log("Response from server:", response.data);
      alert("Data submitted successfully");
    } catch (error) {
      console.error("Submission error:", error);
      alert("Error submitting data");
    }
  };

  // If ANY store is missing monthly data, block the entire form:
  if (anyMissingMonthlyData) {
    // Create a list of stores that are missing monthly data
    const missingStores = supervisedStores.filter(
      (s) => !s.hasMonthlyData || s.hasMonthlyData === 0
    );

    return (
      <Box maxW="700px" mx="auto" p={4}>
        <Heading size="md" mb={2}>
          Weekly Data Entry
        </Heading>

        <Box
          p={4}
          borderWidth="1px"
          borderColor={borderCol}
          borderRadius="md"
          bg={panelBg}
          mt={4}
          boxShadow="md"
        >
          <Text color={highlightColor} fontWeight="bold" fontSize="sm" mb={2}>
            One or more of your stores is missing monthly data. You must complete
            all Monthly Manual Data before submitting weekly data.
          </Text>

          {/* List the missing stores */}
          <Heading size="sm" mb={2}>
            Stores Missing Monthly Data:
          </Heading>
          <UnorderedList spacing={1}>
            {missingStores.map((store) => (
              <ListItem key={store.store_id}>{store.store_name}</ListItem>
            ))}
          </UnorderedList>

          {/* Button to go to Monthly Manual */}
          <Button
            as={Link} // using react-router-dom’s <Link> underneath
            to="/monthly-manual" 
            colorScheme="blue"
            size="sm"
            mt={4}
          >
            Go to Monthly Manual
          </Button>
        </Box>
      </Box>
    );
  }

  // Otherwise, show the normal Weekly form
  return (
    <Box
      maxW="700px"
      mx="auto"
      borderWidth="1px"
      borderColor={borderCol}
      borderRadius="md"
      mb={4}
      mt={2}
      boxShadow="md"
    >
      {/* Toggle bar at top */}
      <Box
        as="button"
        w="100%"
        textAlign="center"
        onClick={toggleFormVisibility}
        bgGradient="linear(to-r, blue.500, blue.300)"
        color="white"
        _hover={{
          bgGradient: "linear(to-r, blue.600, blue.400)",
        }}
        borderBottomWidth="1px"
        borderColor={borderCol}
        py={2}
      >
        <Text fontSize="sm" fontWeight="bold">
          {isFormVisible ? "▲ Hide Form" : "▼ Show Form"}
        </Text>
      </Box>

      {/* Collapsible form */}
      <Collapse in={isFormVisible} animateOpacity>
        <Box
          as="form"
          onSubmit={handleSubmit}
          bg={panelBg}
          p={4}
          borderBottomRadius="md"
        >
          <Heading size="md" mb={2}>
            Weekly Data Entry
          </Heading>
          <Text fontSize="sm" mb={4}>
            Please fill in the form below
          </Text>

          {/* Store Selection */}
          <FormControl mb={4}>
            <FormLabel fontSize="sm" fontWeight="semibold">
              Select Store
            </FormLabel>
            <Select
              name="storeSelection"
              value={formData.storeSelection}
              onChange={handleChange}
              placeholder="Please select a store"
              required
            >
              {supervisedStores.map((storeObj) => (
                <option key={storeObj.store_id} value={storeObj.store_id}>
                  {storeObj.store_name}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* If store selected => show the rest */}
          {formData.storeSelection !== "-1" && (
            <>
              {/* Hours => either split week or full */}
              {dateRanges.hoursStartNextMonthRange ? (
                <>
                  <FormControl mb={2}>
                    <FormLabel fontSize="sm" fontWeight="semibold">
                      Hours Worked (Ending Month)
                    </FormLabel>
                    <Input
                      type="number"
                      name="hoursEndingMonth"
                      placeholder={`Hours: ${dateRanges.hoursEndMonthRange}`}
                      value={formData.hoursEndingMonth}
                      onChange={handleChange}
                      required
                    />
                  </FormControl>

                  <FormControl mb={4}>
                    <FormLabel fontSize="sm" fontWeight="semibold">
                      Hours Worked (Beginning Next Month)
                    </FormLabel>
                    <Input
                      type="number"
                      name="hoursBeginningMonth"
                      placeholder={`Hours: ${dateRanges.hoursStartNextMonthRange}`}
                      value={formData.hoursBeginningMonth}
                      onChange={handleChange}
                      required
                    />
                  </FormControl>
                </>
              ) : (
                <FormControl mb={4}>
                  <FormLabel fontSize="sm" fontWeight="semibold">
                    Hours for the Week
                  </FormLabel>
                  <Input
                    type="number"
                    name="hoursEndingMonth"
                    placeholder={`Hours: ${dateRanges.hoursEndMonthRange}`}
                    value={formData.hoursEndingMonth}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              )}

              {/* Food Variance */}
              <FormControl mb={2}>
                <FormLabel fontSize="sm" fontWeight="semibold">
                  Food Variance (Week)
                </FormLabel>
                <Input
                  type="number"
                  name="foodVarianceWeek"
                  placeholder="Food Variance (Week)"
                  value={formData.foodVarianceWeek}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel fontSize="sm" fontWeight="semibold">
                  Food Variance (Month to Date: {dateRanges.currentMonth})
                </FormLabel>
                <Input
                  type="number"
                  name="foodVarianceMonth"
                  placeholder="Food Variance (Month)"
                  value={formData.foodVarianceMonth}
                  onChange={handleChange}
                  required
                />
              </FormControl>

              {/* PoPs Data (now always shown) */}
              <Box
                borderWidth="1px"
                p={3}
                mb={4}
                borderColor={borderCol}
                borderRadius="md"
              >
                <Heading size="sm" mb={2}>
                  PoPs Data
                </Heading>

                {/* Hidden Mystery Shop Score (just keep for completeness) */}
                <Input
                  type="hidden"
                  name="mysteryShopScore"
                  value={popsFormData.mysteryShopScore}
                  onChange={handlePopsChange}
                />

                <FormControl mb={2}>
                  <FormLabel fontSize="sm" fontWeight="semibold">
                    Sync Reports (MTD)
                  </FormLabel>
                  <Input
                    type="number"
                    name="syncReports"
                    placeholder="Sync Reports MTD"
                    value={popsFormData.syncReports}
                    onChange={handlePopsChange}
                    required
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize="sm" fontWeight="semibold">
                    Actual F&P (MTD)
                  </FormLabel>
                  <Input
                    type="number"
                    name="actualFoodAndPaperCost"
                    placeholder="Enter e.g. 28.77"
                    value={popsFormData.actualFoodAndPaperCost}
                    onChange={handlePopsChange}
                    required
                  />
                </FormControl>
              </Box>
            </>
          )}

          {/* Submit Button aligned to right */}
          <Flex justify="flex-end">
            <Button colorScheme="blue" mt={2} type="submit">
              Submit
            </Button>
          </Flex>
        </Box>
      </Collapse>
    </Box>
  );
}

export default WeeklyDataEntryForm;
