// File: src/components/ProlifiField/Schedules/wsn/super/WeekRangeSelector.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getBaseUrl } from '../../../../utils/getBaseUrl'; // <-- your existing function
import { Box, Text, Button, useDisclosure } from '@chakra-ui/react';
import './WeekRangeSelector.css';
// Import the WeeklyStoreNotesModal (adjust the relative path as necessary)
import WeeklyStoreNotesModal from '../../wsn/super/WeeklyStoreNotesModal';

export default function WeekRangeSelector({
  startDate,
  endDate,
  onWeekSelected,
  weeklyOverallHours = 0,       // total scheduled for Monday–Sunday
  weeklyData = []               // full weekly data so we can compute store count
}) {
  const [mondayDate, setMondayDate] = useState(null);
  const [sundayDate, setSundayDate] = useState(null);
  const [isComplete, setIsComplete] = useState(false);

  // Load stores internally
  const [stores, setStores] = useState([]);
  const [loadingStores, setLoadingStores] = useState(false);

  // useDisclosure hook to control the modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Load stores from the backend
  useEffect(() => {
    async function loadStores() {
      try {
        setLoadingStores(true);
        const url = `${getBaseUrl()}/pfield_wsn/stores`;
        const res = await axios.get(url, { withCredentials: true });
        setStores(res.data || []);
      } catch (err) {
        console.error('Error fetching WSN stores =>', err);
      } finally {
        setLoadingStores(false);
      }
    }
    loadStores();
  }, []);

  useEffect(() => {
    if (!startDate) return;
    const d = new Date(startDate);
    if (!isNaN(d)) {
      const mon = new Date(d);
      setMondayDate(mon);

      const sun = new Date(mon);
      sun.setDate(sun.getDate() + 6);
      sun.setHours(23, 59, 59, 999);
      setSundayDate(sun);
    }
  }, [startDate]);

  useEffect(() => {
    if (!mondayDate || !sundayDate) return;
    checkIfWeekComplete(mondayDate, sundayDate);
  }, [mondayDate, sundayDate]);

  if (!mondayDate || !sundayDate) {
    return (
      <div className="wrs-container">
        <h4 className="wrs-title">Week Range Selector</h4>
        <p>Loading…</p>
      </div>
    );
  }

  const labelStr = formatMD(mondayDate) + ' - ' + formatMD(sundayDate);

  function shiftWeek(days) {
    const newMonday = new Date(mondayDate);
    newMonday.setDate(newMonday.getDate() + days);

    const newSunday = new Date(newMonday);
    newSunday.setDate(newSunday.getDate() + 6);
    newSunday.setHours(23, 59, 59, 999);

    setMondayDate(newMonday);
    setSundayDate(newSunday);
    onWeekSelected?.(newMonday, newSunday);
  }

  function getRequiredHours(weeklyDataArray) {
    // Filter out Office / Vacation
    const storeRows = weeklyDataArray.filter(
      (row) => !['Office', 'Vacation'].includes(row.combined_id)
    );
    // Unique store count
    const uniqueStoreIds = new Set(
      storeRows.map((row) => row.storeName || row.combined_id)
    );
    const storeCount = uniqueStoreIds.size;
    // If 4+ stores => 45 hours, else 8 hrs per store
    return storeCount >= 4 ? 45 : storeCount * 8;
  }

  const requiredHours = getRequiredHours(weeklyData);
  console.log('[WeekRangeSelector] weeklyOverallHours =>', weeklyOverallHours);
  console.log('[WeekRangeSelector] requiredHours =>', requiredHours);

  const aggregatorClass =
    weeklyOverallHours < requiredHours
      ? 'wrs-aggregator wrs-aggregator-alert'
      : 'wrs-aggregator';

  async function checkIfWeekComplete(mon, sun) {
    try {
      const baseUrl = getBaseUrl();
      const isoMon = toYyyyMmDd(mon);
      const isoSun = toYyyyMmDd(sun);

      const url = `${baseUrl}/pfield_events/isWeekComplete?weekStart=${isoMon}&weekEnd=${isoSun}`;
      const res = await axios.get(url, { withCredentials: true });

      setIsComplete(!!res.data.isComplete);
    } catch (err) {
      console.error('Error checking isWeekComplete =>', err);
      setIsComplete(false);
    }
  }

  async function handleMarkComplete() {
    try {
      if (!mondayDate || !sundayDate) return;
      const isoMon = toYyyyMmDd(mondayDate);
      const isoSun = toYyyyMmDd(sundayDate);

      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/markWeekComplete`;
      const payload = { weekStart: isoMon, weekEnd: isoSun };
      const res = await axios.post(url, payload, { withCredentials: true });

      console.log('markWeekComplete =>', res.data);
      setIsComplete(true);
    } catch (err) {
      console.error('Error marking week complete =>', err);
      alert('Could not mark week complete => ' + err.message);
    }
  }

  const buttonLabel = isComplete ? 'Schedule Submitted' : 'Mark Week Complete';
  const buttonClass = isComplete
    ? 'wrs-mark-complete wrs-complete-disabled'
    : 'wrs-mark-complete';

  return (
    <div className="wrs-container">
      <h4 className="wrs-title">Week Range Selector</h4>

      {/* Hidden "Mark Complete" button */}
      <button
        className={buttonClass}
        onClick={handleMarkComplete}
        disabled={isComplete}
        style={{ display: 'none' }}
      >
        {buttonLabel}
      </button>

      <div className="wrs-row">
        <button className="wrs-arrow-btn" onClick={() => shiftWeek(-7)}>
          &lt;
        </button>
        <span className="wrs-label">{labelStr}</span>
        <button className="wrs-arrow-btn" onClick={() => shiftWeek(7)}>
          &gt;
        </button>
      </div>

      <div className="wrs-overall-row">
        <span className={aggregatorClass}>
          This Week’s Hours: <strong>{weeklyOverallHours}</strong>
        </span>
      </div>

      {/* If stores are still loading, show a loading message */}
      {loadingStores && (
        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          <Text>Loading stores…</Text>
        </div>
      )}

      {/* Modern, professional button to open the Weekly Store Notes Modal */}
      {(!loadingStores && stores.length > 0) && (
        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={onOpen}
            px={4}
            py={2}
            borderRadius="md"
            fontWeight="medium"
            fontSize="sm"
          >
            Open Weekly Notes
          </Button>
        </div>
      )}

      {/* Render the modal regardless; it will display based on isOpen */}
      <WeeklyStoreNotesModal isOpen={isOpen} onClose={onClose} stores={stores} />
    </div>
  );
}

// Show e.g. "3/12"
function formatMD(d) {
  const mm = d.getMonth() + 1;
  const dd = d.getDate();
  return mm + '/' + dd;
}

// Return "YYYY-MM-DD" from a JS Date
function toYyyyMmDd(dateObj) {
  const yyyy = dateObj.getFullYear();
  const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
  const dd = String(dateObj.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}
