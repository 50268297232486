// File: src/components/ProlifiField/Schedules/wsn/super/WillingToWorkAtStores.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Text,
  Checkbox,
  CheckboxGroup,
  Spinner,
  useColorModeValue
} from '@chakra-ui/react';
import axios from 'axios';
import { getBaseUrl } from '../../../../utils/getBaseUrl';

/**
 * Renders a list of checkboxes for all the "supervised" stores
 * that the current user can select from.
 *
 * Props:
 *  - selectedStores (array of store IDs **as strings**)
 *  - onChange (function) => called with updated array of store IDs (still strings)
 */
export default function WillingToWorkAtStores({ selectedStores = [], onChange }) {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);

  const boxBg = useColorModeValue('gray.50', 'gray.700');
  const boxBorderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    loadSupervisedStores();
  }, []);

  async function loadSupervisedStores() {
    try {
      const url = `${getBaseUrl()}/pfield_wsn/stores`;
      const res = await axios.get(url, { withCredentials: true });
      setStores(res.data || []);
    } catch (err) {
      console.error('[WillingToWorkAtStores] Error fetching supervised stores:', err);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Called when the user checks/unchecks any store checkbox.
   * `newValues` is an array of currently checked store IDs (as strings).
   */
  function handleCheckboxChange(newValues) {
    // newValues is always an array of strings from Chakra
    onChange(newValues);
  }

  if (loading) {
    return (
      <Box py={4} textAlign="center">
        <Spinner />
        <Text mt={2} fontSize="sm" color="gray.500">
          Loading stores...
        </Text>
      </Box>
    );
  }

  if (!stores.length) {
    return (
      <Text fontSize="sm" color="gray.600">
        No supervised stores found.
      </Text>
    );
  }

  return (
    <Box
      p={4}
      bg={boxBg}
      borderRadius="md"
      borderWidth="1px"
      borderColor={boxBorderColor}
      boxShadow="sm"
    >
      <CheckboxGroup
        value={selectedStores}      // must be an array of strings
        onChange={handleCheckboxChange}
        colorScheme="blue"
      >
        <SimpleGrid columns={[1, 2, 3]} spacingY={2} spacingX={4}>
          {stores.map((store) => (
            <Checkbox
              key={store.store_id}
              value={String(store.store_id)} // ensure this is a string
              fontSize="sm"
            >
              {store.store_name}
            </Checkbox>
          ))}
        </SimpleGrid>
      </CheckboxGroup>
    </Box>
  );
}
