import React from 'react';
import PropTypes from 'prop-types';

export default function SpreadsheetView({ notesData, storesData }) {
  const storesArray = Object.entries(notesData);

  const getStoreName = (storeId) => {
    const storeInfo = storesData.find(s => String(s.store_id) === String(storeId));
    return storeInfo ? storeInfo.store_name : storeId;
  };

  // Helper to map willingStores IDs to store names.
  const getWillingStoresNames = (willingStores) => {
    if (!Array.isArray(willingStores) || willingStores.length === 0) {
      return 'N/A';
    }
    return willingStores.map(storeId => {
      const storeInfo = storesData.find(s => String(s.store_id) === String(storeId));
      return storeInfo ? storeInfo.store_name : storeId;
    }).join(', ');
  };

  return (
    <div className="spreadsheet-view">
      <table className="spreadsheet-table">
        <thead>
          <tr>
            <th>Store Name</th>
            <th>People</th>
            <th>Operations</th>
            <th>Profit</th>
            <th>Sales</th>
            <th>Follow Up Tasks</th>
            <th>Employee Funds (Spent)</th>
            <th>Employee Funds (Summary)</th>
            <th>MIT Name</th>
            <th>MIT Rating</th>
            <th>MIT Time Spent</th>
            <th>MIT Accomplishments</th>
            <th>MIT Willing Stores</th>
            <th>MIT Photo URL</th>
          </tr>
        </thead>
        <tbody>
          {storesArray.map(([storeId, data]) => {
            const mitCards = data.mitCards || [];
            return (
              <tr key={storeId}>
                <td>{getStoreName(storeId)}</td>
                <td>{data.people || 'N/A'}</td>
                <td>{data.operations || 'N/A'}</td>
                <td>{data.profit || 'N/A'}</td>
                <td>{data.sales || 'N/A'}</td>
                <td>{data.followUpTasks || 'N/A'}</td>
                <td>
                  {data.employeeFunds && data.employeeFunds.spent !== 0
                    ? data.employeeFunds.spent
                    : 'N/A'}
                </td>
                <td>
                  {data.employeeFunds && data.employeeFunds.summary
                    ? data.employeeFunds.summary
                    : 'N/A'}
                </td>
                <td>
                  {mitCards.length > 0 ? mitCards.map(card => card.name).join(', ') : 'N/A'}
                </td>
                <td>
                  {mitCards.length > 0 ? mitCards.map(card => card.rating).join(', ') : 'N/A'}
                </td>
                <td>
                  {mitCards.length > 0 ? mitCards.map(card => card.timeSpent).join(', ') : 'N/A'}
                </td>
                <td>
                  {mitCards.length > 0 ? mitCards.map(card => card.accomplishments).join(', ') : 'N/A'}
                </td>
                <td>
                  {mitCards.length > 0
                    ? mitCards.map(card => getWillingStoresNames(card.willingStores)).join(' | ')
                    : 'N/A'}
                </td>
                <td>
                  {mitCards.length > 0 ? mitCards.map(card => card.mitPhotoUrl || 'N/A').join(', ') : 'N/A'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

SpreadsheetView.propTypes = {
  notesData: PropTypes.object.isRequired,
  storesData: PropTypes.array.isRequired,
};
