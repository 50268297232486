// FILE: src/components/TZFileUploadV2.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import styles from './TZFileUploadV2.module.css';
import LoadingIndicator from '../LoadingIndicator';

function TZFileUploadV2() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [showMonthSelector, setShowMonthSelector] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recentUpload, setRecentUpload] = useState(null);

  // Helper to determine backend base URL
  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000';
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app';
    } else {
      return '';
    }
  };

  // On mount, determine if the month selector should be shown and fetch the recent upload date.
  useEffect(() => {
    const today = new Date();
    if (today.getDate() <= 5) {
      setShowMonthSelector(true);
      setSelectedDate(today);
    }

    const fetchRecentUpload = async () => {
      try {
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/tzv2/recent-upload`, {
          withCredentials: true
        });
        setRecentUpload(response.data.mostRecent);
      } catch (err) {
        console.error("Error fetching recent upload:", err);
      }
    };
    fetchRecentUpload();
  }, []);

  const formatToYYYYMM = (dateObj) => {
    if (!dateObj || !(dateObj instanceof Date) || isNaN(dateObj)) {
      return '';
    }
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setSelectedFiles(Array.from(files));
      setError(null);
      setUploadStatus(null);
    } else {
      setSelectedFiles([]);
    }
  };

  const handleProcessClick = () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      setError('Please select at least one Excel file before proceeding.');
      return;
    }
    handleMainUpload();
  };

  const handleMainUpload = async () => {
    setIsLoading(true);
    setError(null);
    setUploadStatus(null);

    try {
      const baseUrl = getBaseUrl();
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append('excelFiles', file);
      });

      if (showMonthSelector && selectedDate) {
        const submissionMonth = formatToYYYYMM(selectedDate);
        if (submissionMonth) {
          formData.append('submissionMonth', submissionMonth);
        }
      }

      const response = await axios.post(`${baseUrl}/api/tzv2/TZv21Upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      });

      console.log("Results: ", response.data.results);
      setUploadStatus('Files processed successfully');
    } catch (err) {
      console.error('Error uploading files:', err);
      setError('An error occurred while processing the files. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStoreReminders = async () => {
    setIsLoading(true);
    setError(null);
    setUploadStatus(null);

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.get(`${baseUrl}/api/tzv2/store-reminders`, {
        withCredentials: true
      });
      console.log("PROCESSED DATA:", response.data.processedData);
      console.log("Objectives Data:", response.data.objectivesByStore);
      console.log("Supervisor Data:", response.data.objectivesBySupervisor);
      setUploadStatus('Store reminders processed successfully!');
    } catch (err) {
      console.error("Error calling store reminders route:", err);
      setError('Error calling store reminders route. Check console for details.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDirectorReport = async () => {
    setIsLoading(true);
    setError(null);
    setUploadStatus(null);

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.get(`${baseUrl}/api/tzv2/director-report`, {
        withCredentials: true
      });
      console.log("Director Report Response:", response.data);
      setUploadStatus('Director report fetched successfully!');
    } catch (err) {
      console.error("Error calling director-report route:", err);
      setError('Error calling director-report route. Check console for details.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {/* Main content */}
      <h2 className={styles.title}>TotZone Upload v2</h2>
      
      <div className={styles.recentUpload}>
        <strong>Most Recent Upload: </strong>
        {recentUpload 
          ? new Date(recentUpload).toLocaleString() 
          : 'No uploads yet'}
      </div>

      <div className={styles.controlsRow}>
        {showMonthSelector && (
          <div className={styles.monthSelector}>
            <label>Submission Month</label>
            <DatePickerComponent
              placeholder="Pick the month"
              format="MMMM yyyy"
              start="Year"
              depth="Year"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.value)}
            />
          </div>
        )}
      </div>

      <div className={styles.uploadSection}>
        <label className={styles.dragArea}>
          Drag & drop or click to upload
          <input
            type="file"
            accept=".xlsx,.xls"
            multiple
            className={styles.fileInput}
            onChange={handleFileChange}
          />
        </label>
        {error && <div className={styles.errorMessage}>{error}</div>}
      </div>

      {selectedFiles.length > 0 && (
        <div className={styles.filePreview}>
          <h3>Files Selected:</h3>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>
                <p>{file.name}</p>
                <p><strong>Size:</strong> {(file.size / 1024).toFixed(2)} KB</p>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className={styles.buttonContainer}>
        <button className={styles.uploadButton} onClick={handleProcessClick}>
          Process File
        </button>
      </div>

      {(uploadStatus || error) && (
        <div className={styles.resultSection}>
          {uploadStatus && (
            <>
              <h3>TZv2 Results:</h3>
              <p>{uploadStatus}</p>
            </>
          )}
          {error && (
            <div className={styles.errorMessage}>
              <p>{error}</p>
            </div>
          )}
        </div>
      )}

      <div className={styles.emailContainer}>
        <h2 className={styles.emailTitle}>Totzone Emails</h2>
        <p className={styles.emailSubTitle}>Send:</p>
        <div className={styles.emailButtons}>
          <button 
            className={styles.emailButton} 
            onClick={handleStoreReminders} 
          >
            Store Reminders
          </button>
          <button 
            className={styles.emailButton} 
            onClick={handleDirectorReport}
          >
            Director Report
          </button>
          {/* "Under development" button with caution tape, plus tooltip */}
          <button
            className={`${styles.emailButton} ${styles.underDevelopmentDisabled}`}
            disabled
            title="This feature is currently under development"
          >
            First to Complete!
          </button>
        </div>
      </div>

      {/* The overlay for dimming the container, plus the spinner on top. */}
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.spinnerContainer}>
            <LoadingIndicator />
          </div>
        </div>
      )}
    </div>
  );
}

export default TZFileUploadV2;
