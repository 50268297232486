// src/components/BaseLayout.js
import React, { useEffect, useState } from "react";
import { Box, Flex, Button } from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactSelect from "react-select";

import NavBar from "./NavBar";

function BaseLayout({ username, userGroup }) {
  const navigate = useNavigate();

  const [allUsers, setAllUsers] = useState([]);
  const [spoofTarget, setSpoofTarget] = useState("");
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [originalUserGroup, setOriginalUserGroup] = useState(null);
  const [originalUsername, setOriginalUsername] = useState(null);

  // Holds the fetched image as a data URL
  const [fetchedImage, setFetchedImage] = useState(null);

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return "https://prolifi.app";
    }
    return "";
  };

  const checkSessionStatus = async () => {
    try {
      const baseUrl = getBaseUrl();
      const { data } = await axios.get(`${baseUrl}/api/auth/session-status`, {
        withCredentials: true,
      });

      if (data.isAuthenticated) {
        setIsImpersonating(!!data.isImpersonating);
        setOriginalUserGroup(data.originalUserGroup || null);
        setOriginalUsername(data.originalUsername || null);
      }
    } catch (error) {
      console.error("Error checking session status:", error);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const baseUrl = getBaseUrl();
      const res = await axios.get(`${baseUrl}/api/auth/all-users`, {
        withCredentials: true,
      });
      setAllUsers(res.data);
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  // ****************** FETCH PROTECTED IMAGE WITH AXIOS ******************
  const fetchProtectedImage = async () => {
    try {
      const baseUrl = getBaseUrl();
      // 1. Make a request to the protected-image endpoint
      const response = await axios.get(`${baseUrl}/api/protected-image/qr.png`, {
        withCredentials: true,
        responseType: "arraybuffer", 
      });

      // 2. Convert the returned arraybuffer to base64
      const base64 = btoa(
        new Uint8Array(response.data).reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, "")
      );

      // 3. Construct a data URL
      const dataUrl = `data:image/png;base64,${base64}`;

      // 4. Save to state
      setFetchedImage(dataUrl);
    } catch (error) {
      console.error("Error fetching protected image:", error);
    }
  };
  // **********************************************************************

  useEffect(() => {
    checkSessionStatus();

    // If userGroup is Admin, or username/originalUsername is jnauertc/mparker, fetch users
    if (
      userGroup === "Admin" ||
      username === "jnauertc" ||
      username === "mparker" ||
      originalUsername === "jnauertc" ||
      originalUsername === "mparker"
    ) {
      fetchAllUsers();
    }

    // Optionally fetch the protected image as soon as we mount:
    fetchProtectedImage();

    // eslint-disable-next-line
  }, [userGroup, username, originalUsername]);

  const handleSpoof = async () => {
    if (!spoofTarget) return;
    try {
      const baseUrl = getBaseUrl();
      await axios.post(
        `${baseUrl}/api/auth/spoof`,
        { userId: spoofTarget },
        { withCredentials: true }
      );
      window.location.reload();
    } catch (error) {
      console.error("Error spoofing user:", error);
    }
  };

  const stopImpersonation = async () => {
    try {
      const baseUrl = getBaseUrl();
      await axios.post(`${baseUrl}/api/auth/unsnoop`, {}, { withCredentials: true });
      window.location.reload();
    } catch (error) {
      console.error("Error stopping impersonation:", error);
    }
  };

  const handleLogout = async () => {
    try {
      const baseUrl = getBaseUrl();
      await axios.get(`${baseUrl}/api/auth/logout`, { withCredentials: true });
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const userOptions = allUsers.map((u) => ({
    value: u.userId,
    label: u.username,
  }));

  const currentSelection =
    userOptions.find((opt) => opt.value === spoofTarget) || null;

  const shouldShowImpersonationUI =
    userGroup === "Admin" ||
    originalUserGroup === "Admin" ||
    username === "jnauertc" ||
    username === "mparker" ||
    originalUsername === "jnauertc" ||
    originalUsername === "mparker";

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      {/* Top Nav */}
      <NavBar username={username} userGroup={userGroup} />

      {/* Main content area */}
      <Box as="main" flex="1" overflowY="auto">

        <Outlet />
      </Box>

      {/* Footer */}
      <Box as="footer" bg="gray.100" p={1}>
        <Flex justify="space-between" align="center">
          <Box>
            {shouldShowImpersonationUI && (
              <Flex align="center" gap={2}>
                {isImpersonating ? (
                  <Button size="sm" colorScheme="yellow" onClick={stopImpersonation}>
                    Stop Impersonation
                  </Button>
                ) : (
                  <>
                    <Box width="220px">
                      <ReactSelect
                        placeholder="Type to search..."
                        options={userOptions}
                        value={currentSelection}
                        onChange={(selected) => setSpoofTarget(selected.value)}
                        isSearchable
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </Box>
                    <Button size="sm" colorScheme="blue" onClick={handleSpoof}>
                      Impersonate
                    </Button>
                  </>
                )}
              </Flex>
            )}
          </Box>

          <Box>
            <Button size="sm" colorScheme="red" onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default BaseLayout;
