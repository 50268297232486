// FILE: src/components/ProlifiField/wsn/super/WeeklyStoreNotesModal.jsx

import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Text,
  IconButton,
  Flex,
  VStack,
  Box,
  Heading,
  Textarea,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import axios from 'axios';

import { getBaseUrl } from '../../../../utils/getBaseUrl';
import MITSection from './MITSection';
import EmployeeFundsSection from './EmployeeFundsSection';
import { loadWeeklyData } from '../../../../utils/loadWeeklyData';

/**
 * Helper to fetch a protected image from /api/protected-image/:filename
 * and convert it to a base64 data URL.
 */
async function fetchProtectedBase64(filename) {
  if (!filename) return null;

  try {
    const baseUrl = getBaseUrl();
    const response = await axios.get(`${baseUrl}/protected-image/${filename}`, {
      withCredentials: true,
      responseType: 'arraybuffer', // get binary data
    });

    // Convert arraybuffer to base64
    const base64 = btoa(
      new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );

    // Adjust the MIME type if you know it's jpg vs png
    return `data:image/png;base64,${base64}`;
  } catch (error) {
    console.error('Error fetching protected image =>', error);
    return null;
  }
}

/**
 * If today's dayOfWeek is Monday => "this" Monday,
 * otherwise step backward for the Monday of current week.
 */
function getWeekMonday(date) {
  const dayOfWeek = date.getDay(); // 0=Sun,1=Mon,2=Tue,...
  const offset = (dayOfWeek + 6) % 7;
  const monday = new Date(date);
  monday.setDate(date.getDate() - offset);
  return monday;
}

/**
 * Find the final Monday of a given month.
 */
function getLastMondayOfMonth(year, monthIndex) {
  const dt = new Date(year, monthIndex + 1, 0); // last day of that month
  while (dt.getDay() !== 1) {
    dt.setDate(dt.getDate() - 1);
  }
  return dt;
}

/**
 * Compute the report date (a Monday) based on today's date and final submission flag.
 *
 * - If today is Tuesday to Sunday: return the Monday of the current week.
 * - If today is Monday:
 *     - If finalSubmitted is true, return today's date.
 *     - Otherwise, return the Monday of the previous week.
 */
function getReportDate(isFinalSubmitted) {
  const now = new Date();
  if (now.getDay() === 1) { // Monday
    if (isFinalSubmitted) {
      return now; // Use today's date (Monday)
    } else {
      const previousMonday = new Date(now);
      previousMonday.setDate(now.getDate() - 7);
      return previousMonday;
    }
  }
  // For Tuesday to Sunday, use current week's Monday.
  return getWeekMonday(now);
}

/**
 * The default shape if we have no data for that category.
 */
const defaultNotes = {
  people: '',
  operations: '',
  profit: '',
  sales: '',
  mitCards: [],
  followUpTasks: '',
  employeeFunds: { spent: '', summary: '' }
};

export default function WeeklyStoreNotesModal({ isOpen, onClose, stores }) {
  // Which store index is currently shown.
  const [currentIndex, setCurrentIndex] = useState(0);

  // Categories from the server.
  const [categories, setCategories] = useState([]);

  // Big object: notesData[storeId] => { catId => value, catId_photoUrl => string, ... }
  const [notesData, setNotesData] = useState({});

  // Photo attachments (newly picked): photoUploads[storeId][catId] => File.
  const [photoUploads, setPhotoUploads] = useState({});

  // Track unsaved changes.
  const [hasChanges, setHasChanges] = useState(false);

  // For hidden file inputs.
  const fileInputRefs = useRef({});

  // Chakra color usage.
  const modalBg        = useColorModeValue('white', 'gray.800');
  const headerBg       = useColorModeValue('blue.50', 'blue.900');
  const storeTextColor = useColorModeValue('blue.700', 'blue.200');
  const categoryBg     = useColorModeValue('gray.50', 'gray.700');

  /**
   * Load categories when modal opens.
   */
  useEffect(() => {
    if (isOpen) {
      loadCategories();
      setCurrentIndex(0);
      setHasChanges(false);
    }
  }, [isOpen]);

  /**
   * Once categories are loaded, and we have stores, then load the weekly data.
   */
  useEffect(() => {
    if (isOpen && categories.length > 0 && stores && stores.length > 0) {
      loadWeekDataOnMount();
    }
  }, [isOpen, categories, stores]);

  async function loadCategories() {
    try {
      const url = `${getBaseUrl()}/pfield_wsn/categories`;
      const res = await axios.get(url, { withCredentials: true });
      const updatedCats = (res.data || []).map(cat => {
        if (cat.categoryName.toLowerCase() === 'profit') {
          // remove subQuestions if "profit"
          return { ...cat, subQuestions: [] };
        }
        return cat;
      });
      setCategories(updatedCats);
    } catch (err) {
      console.error('Error fetching categories =>', err);
    }
  }

  async function loadWeekDataOnMount() {
    try {
      // Optionally, you could compute start/end dates here using getReportDate(false)
      // and pass them to loadWeeklyData if that function supports parameters.
      const dataByStoreId = await loadWeeklyData(stores);
      // e.g. dataByStoreId: { "2410": { people:"...", peoplePhotoUrl:"people-2410.png", ... } }

      const newState = {};

      // For each store’s data from the server.
      for (const storeIdStr of Object.keys(dataByStoreId)) {
        const storeIdNum = parseInt(storeIdStr, 10);
        if (!newState[storeIdNum]) {
          newState[storeIdNum] = {};
        }
        const serverObj = dataByStoreId[storeIdStr];

        // Map the server data to category IDs in our local state.
        for (const cat of categories) {
          const catNameLower = cat.categoryName.toLowerCase();

          if (cat.type === 'mit' && serverObj.mitCards) {
            newState[storeIdNum][cat.id] = serverObj.mitCards;
          } else if (cat.type === 'employeeFunds' && serverObj.employeeFunds) {
            newState[storeIdNum][cat.id] = serverObj.employeeFunds;
          } else {
            // Normal text categories.
            if (serverObj[catNameLower] !== undefined) {
              newState[storeIdNum][cat.id] = serverObj[catNameLower] || '';
            }

            // If there's a photo filename, fetch from protected route.
            const possiblePhotoUrlKey = catNameLower + 'PhotoUrl';
            if (serverObj[possiblePhotoUrlKey]) {
              const photoFilename = serverObj[possiblePhotoUrlKey];
              // fetch the file and convert to base64.
              const dataUrl = await fetchProtectedBase64(photoFilename);
              newState[storeIdNum][`${cat.id}_photoUrl`] = dataUrl;
            }
          }
        }

        // Also store followUpTasks if present.
        if (serverObj.followUpTasks) {
          newState[storeIdNum].followUpTasks = serverObj.followUpTasks;
        }
      }

      setNotesData(prev => ({ ...prev, ...newState }));
      setHasChanges(false);
    } catch (err) {
      console.error('Error loading weekly data =>', err);
    }
  }

  const isEmpty = !stores || stores.length === 0;
  const currentStore = isEmpty ? null : stores[currentIndex];
  const storeId = currentStore?.store_id || null;

  /**
   * Whenever the storeId or categories change, ensure we have a notes structure in state.
   */
  useEffect(() => {
    if (storeId && categories.length > 0) {
      setNotesData(prev => {
        if (!prev[storeId]) {
          const init = {};
          categories.forEach(cat => {
            if (cat.type === 'textarea') {
              if (cat.subQuestions?.length) {
                init[cat.id] = cat.subQuestions.map(() => '');
              } else {
                init[cat.id] = '';
              }
            } else if (cat.type === 'mit') {
              init[cat.id] = [];
            } else if (cat.type === 'employeeFunds') {
              init[cat.id] = { spent: '', summary: '' };
            } else {
              init[cat.id] = '';
            }
          });
          return { ...prev, [storeId]: init };
        }
        return prev;
      });

      setPhotoUploads(prev => {
        if (!prev[storeId]) {
          return { ...prev, [storeId]: {} };
        }
        return prev;
      });
    }
  }, [storeId, categories]);

  const currentNotes = storeId && notesData[storeId] ? notesData[storeId] : defaultNotes;

  function updateNote(categoryId, value) {
    if (!storeId) return;
    setNotesData(prev => ({
      ...prev,
      [storeId]: {
        ...prev[storeId],
        [categoryId]: value
      }
    }));
    setHasChanges(true);
  }

  function updateSubNote(categoryId, subIndex, value) {
    if (!storeId) return;
    setNotesData(prev => {
      const arr = [...(prev[storeId][categoryId] || [])];
      arr[subIndex] = value;
      return {
        ...prev,
        [storeId]: {
          ...prev[storeId],
          [categoryId]: arr
        }
      };
    });
    setHasChanges(true);
  }

  function goPrev() {
    setCurrentIndex(i => (i > 0 ? i - 1 : i));
  }
  function goNext() {
    setCurrentIndex(i => (i < stores.length - 1 ? i + 1 : i));
  }

  function attachPhoto(catId) {
    if (!storeId) return;
    const key = `${storeId}-${catId}`;
    if (fileInputRefs.current[key]) {
      fileInputRefs.current[key].click();
    }
  }

  function handleFileChange(catId, e) {
    if (!storeId) return;
    const file = e.target.files?.[0] || null;
    setPhotoUploads(prev => ({
      ...prev,
      [storeId]: {
        ...(prev[storeId] || {}),
        [catId]: file
      }
    }));
    setHasChanges(true);
  }

  // New helper: Check if today's date is in the week of the 3rd Monday of the month.
  const now = new Date();
  function isWeekOfThirdMonday(date) {
    const year = date.getFullYear();
    const month = date.getMonth();

    // Calculate the first Monday of the month.
    let firstMonday = new Date(year, month, 1);
    while (firstMonday.getDay() !== 1) {
      firstMonday.setDate(firstMonday.getDate() + 1);
    }

    // The 3rd Monday is two weeks after the first Monday.
    const thirdMonday = new Date(firstMonday);
    thirdMonday.setDate(firstMonday.getDate() + 14);

    // Define the end of the week (Sunday) for the 3rd Monday week.
    const weekEnd = new Date(thirdMonday);
    weekEnd.setDate(thirdMonday.getDate() + 6);

    // Normalize the date (set time to midnight) for an accurate comparison.
    const normalizedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    return normalizedDate >= thirdMonday && normalizedDate <= weekEnd;
  }

  // Change: Employee Funds are now required on the final Monday.
  function isFinalMonday(date) {
    const finalMonday = getLastMondayOfMonth(date.getFullYear(), date.getMonth());
    const normalizedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const normalizedFinalMonday = new Date(finalMonday.getFullYear(), finalMonday.getMonth(), finalMonday.getDate());
    return normalizedDate.getTime() === normalizedFinalMonday.getTime();
  }

  /**
   * Validate that all required fields are filled out before final submission.
   * For each store, we require:
   *   - Text fields for People, Operations, Profit, and Sales.
   *   - Employee Funds are required only on the final Monday.
   * 
   * For the MIT section, if today is the 3rd Monday, then overall at least one MIT must be provided.
   * (The only valid submission with no MITs is when the user explicitly clicks the "No MIT" button, which should result in a MIT entry with a noMIT flag.)
   */
  function validateFinalSubmission() {
    const errors = [];
    // Required text categories (by name, case-insensitive).
    const requiredTextCategories = ['people', 'operations', 'profit', 'sales'];

    for (const store of stores) {
      const sid = store.store_id;
      const storeNotes = notesData[sid] || {};

      requiredTextCategories.forEach(catName => {
        const cat = categories.find(
          x => x.categoryName.toLowerCase() === catName.toLowerCase()
        );
        if (cat) {
          const value = storeNotes[cat.id];
          if (!value || value.trim() === '') {
            errors.push(`Store ${store.store_id} (${store.store_name}): ${cat.categoryName} is required.`);
          }
        }
      });

      // Validate Employee Funds only on the final Monday.
      if (isFinalMonday(now)) {
        const empFundsCat = categories.find(c => c.type === 'employeeFunds');
        if (empFundsCat) {
          const fundsData = storeNotes[empFundsCat.id] || { spent: '', summary: '' };
          if (!fundsData.spent || fundsData.spent.toString().trim() === '') {
            errors.push(`Store ${store.store_id} (${store.store_name}): Employee Funds "spent" is required.`);
          }
          if (!fundsData.summary || fundsData.summary.trim() === '') {
            errors.push(`Store ${store.store_id} (${store.store_name}): Employee Funds "summary" is required.`);
          }
        }
      }
    }

    // MIT validation: On the 3rd Monday, check that overall there is at least one MIT card.
    if (isWeekOfThirdMonday(now)) {
      let overallMITCount = 0;
      stores.forEach(store => {
        const sid = store.store_id;
        const storeNotes = notesData[sid] || {};
        const mitCat = categories.find(c => c.type === 'mit');
        if (mitCat) {
          const mitCards = storeNotes[mitCat.id] || [];
          overallMITCount += mitCards.length;
        }
      });
      if (overallMITCount === 0) {
        errors.push("On the 3rd Monday, you must have at least one MIT card overall. If none, please click 'No MIT'.");
      }
    }

    if (errors.length > 0) {
      alert("Final submission failed validation:\n" + errors.join("\n"));
      return false;
    }
    return true;
  }

  // Reusable code for saving: final or not.
  async function saveAllStoresToDb(isFinal) {
    if (!stores || stores.length === 0 || categories.length === 0) {
      return;
    }

    if (isFinal) {
      // If final => confirm with user.
      const confirmFinal = window.confirm(
        "Are you sure you are completely done with weekly store notes? " +
        "Once submitted as final, you typically cannot revert changes!"
      );
      if (!confirmFinal) return;
    }

    try {
      // Use our new helper to determine the correct report date.
      const reportDate = getReportDate(isFinal);
      const reportDateStr = reportDate.toISOString().slice(0, 10);

      // Build the payload for all stores.
      const storesData = [];

      for (const store of stores) {
        const sid = store.store_id;
        const storeNotes = notesData[sid] || {};
        const storePayload = {
          store_id: sid,
          report_date: reportDateStr,
          people_text: '',
          operations_text: '',
          profit_text: '',
          sales_text: '',
          follow_up_tasks: storeNotes.followUpTasks || '',
          employee_spent: '',
          employee_summary: '',
          mitCards: [],
          // final_submitted => 1 if isFinal is true, else 0.
          final_submitted: isFinal
        };

        // Map categories.
        function getCatIdByName(catName) {
          const c = categories.find(x => x.categoryName.toLowerCase() === catName.toLowerCase());
          return c ? c.id : null;
        }
        const peopleId     = getCatIdByName('people');
        const operationsId = getCatIdByName('operations');
        const profitId     = getCatIdByName('profit');
        const salesId      = getCatIdByName('sales');

        if (peopleId)     storePayload.people_text     = storeNotes[peopleId]     || '';
        if (operationsId) storePayload.operations_text = storeNotes[operationsId] || '';
        if (profitId)     storePayload.profit_text     = storeNotes[profitId]     || '';
        if (salesId)      storePayload.sales_text      = storeNotes[salesId]      || '';

        // Employee Funds.
        const fundsCat = categories.find(c => c.type === 'employeeFunds');
        if (fundsCat) {
          const { spent, summary } = storeNotes[fundsCat.id] || { spent: '', summary: '' };
          storePayload.employee_spent   = spent;
          storePayload.employee_summary = summary;
        }

        // MIT.
        const mitCat = categories.find(c => c.type === 'mit');
        const mitArr = mitCat ? (storeNotes[mitCat.id] || []) : [];
        storePayload.mitCards = mitArr.map(card => {
          const { mitPhotoFile, ...rest } = card;
          return rest;
        });

        storesData.push(storePayload);
      }

      // Build a FormData for the file uploads.
      const formData = new FormData();
      formData.append('storesData', JSON.stringify(storesData));

      // Category-level photos.
      for (const store of stores) {
        const sid = store.store_id;
        const catPhotos = photoUploads[sid] || {};
        for (const cat of categories) {
          if (
            cat.photoAttachmentOption === 1 &&
            cat.type !== 'mit' &&
            cat.type !== 'employeeFunds'
          ) {
            const file = catPhotos[cat.id];
            if (file) {
              formData.append(`photo_${sid}_${cat.categoryName.toLowerCase()}`, file);
            }
          }
        }
      }

      // MIT photos.
      for (const store of stores) {
        const sid = store.store_id;
        const storeNotes = notesData[sid] || {};
        const mitCat = categories.find(c => c.type === 'mit');
        if (mitCat) {
          const mitArr = storeNotes[mitCat.id] || [];
          mitArr.forEach((card, idx) => {
            if (card.mitPhotoFile) {
              formData.append(`mitPhoto_${sid}_${idx}`, card.mitPhotoFile);
            }
          });
        }
      }

      // POST to the server.
      await axios.post(`${getBaseUrl()}/pfield_wsn/saveNotes`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      // On success.
      setHasChanges(false);
      if (isFinal) {
        alert('All data submitted as FINAL!');
      } else {
        alert('Draft saved successfully!');
      }
    } catch (err) {
      console.error('Error saving data =>', err);
      alert('Error saving data. Check console.');
    }
  }

  // Handler for "Draft Save" (no validation needed).
  function handleSaveDraft() {
    saveAllStoresToDb(false);
  }

  // Handler for "Submit Final" (with validation).
  function handleSubmitFinalData() {
    if (!validateFinalSubmission()) {
      return;
    }
    saveAllStoresToDb(true);
  }

  if (isEmpty) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered scrollBehavior="inside">
        <ModalOverlay bg="blackAlpha.600" />
        <ModalContent bg={modalBg} borderRadius="12px" boxShadow="2xl">
          <ModalHeader bg={headerBg} fontSize="xl" fontWeight="bold" borderBottom="1px solid" borderColor="gray.200">
            Weekly Store Notes
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>No stores found, or not authorized.</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay bg="blackAlpha.600" />

      <ModalContent
        bg={modalBg}
        borderRadius="12px"
        boxShadow="2xl"
        overflow="hidden"
        w={{ base: '100vw', md: '80vw' }}
        maxH={{ base: '100vh', md: '90vh' }}
      >
        <ModalHeader
          bg={headerBg}
          fontSize="xl"
          fontWeight="bold"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          Weekly Store Notes
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody overflowY="auto" py={4}>
          {/* Store Navigation */}
          <Flex align="center" justify="center" mb={6} h="40px">
            <IconButton
              icon={<ArrowLeftIcon />}
              aria-label="Previous store"
              variant="ghost"
              isDisabled={currentIndex === 0}
              onClick={goPrev}
              mr={2}
            />
            <Text
              fontSize="md"
              fontWeight="medium"
              color={storeTextColor}
              lineHeight="40px"
            >
              #{currentStore.store_id} – {currentStore.store_name}
            </Text>
            <IconButton
              icon={<ArrowRightIcon />}
              aria-label="Next store"
              variant="ghost"
              isDisabled={currentIndex === stores.length - 1}
              onClick={goNext}
              ml={2}
            />
          </Flex>

          {/* Unsaved Changes Alert */}
          {hasChanges && (
            <Alert status="warning" variant="left-accent" mb={6}>
              <AlertIcon />
              <Box flex="1">
                <AlertTitle>Unsaved Changes!</AlertTitle>
                <AlertDescription>
                  You have unsaved changes. Click "Save" to store a draft, or "Submit Final Data" when completely done.
                </AlertDescription>
              </Box>
            </Alert>
          )}

          <VStack spacing={8} align="stretch">
            {categories.map(cat => {
              // Only render EmployeeFundsSection if today's date meets its criteria.
              if (cat.type === 'employeeFunds' && !isFinalMonday(now)) {
                return null;
              }

              return (
                <Box
                  key={cat.id}
                  p={4}
                  bg={categoryBg}
                  borderWidth="1px"
                  borderRadius="md"
                  boxShadow="sm"
                >
                  <Heading size="md" mb={2}>{cat.categoryName}</Heading>
                  <Text mb={2}>{cat.question}</Text>

                  {/* TEXTAREA, no subQuestions */}
                  {cat.type === 'textarea' && !cat.subQuestions?.length && (
                    <Textarea
                      placeholder={cat.placeholder || ''}
                      size="sm"
                      mb={2}
                      value={currentNotes[cat.id] || ''}
                      onChange={(e) => updateNote(cat.id, e.target.value)}
                    />
                  )}

                  {/* TEXTAREA with subQuestions */}
                  {cat.type === 'textarea' && cat.subQuestions?.length > 0 && (
                    cat.subQuestions.map((sq, idx) => (
                      <Box key={idx} mt={2}>
                        <Text fontWeight="semibold">{sq.label}</Text>
                        <Textarea
                          placeholder={sq.placeholder}
                          size="sm"
                          value={
                            Array.isArray(currentNotes[cat.id])
                              ? currentNotes[cat.id][idx] || ''
                              : ''
                          }
                          onChange={(ev) => updateSubNote(cat.id, idx, ev.target.value)}
                        />
                      </Box>
                    ))
                  )}

                  {/* MIT */}
                  {cat.type === 'mit' && (
                    <MITSection
                      mitCards={currentNotes[cat.id] || []}
                      onChange={(newMitCards) => {
                        updateNote(cat.id, newMitCards);
                      }}
                    />
                  )}

                  {/* Employee Funds */}
                  {cat.type === 'employeeFunds' && (
                    <EmployeeFundsSection
                      fundsData={currentNotes[cat.id] || { spent: '', summary: '' }}
                      onChange={(newFunds) => {
                        updateNote(cat.id, newFunds);
                      }}
                    />
                  )}

                  {/* Photo attachments => if cat.photoAttachmentOption=1, not MIT/EF */}
                  {cat.photoAttachmentOption === 1 &&
                   cat.type !== 'mit' &&
                   cat.type !== 'employeeFunds' && (
                    <>
                      <Button
                        size="sm"
                        variant="outline"
                        colorScheme="blue"
                        mt={3}
                        onClick={() => attachPhoto(cat.id)}
                      >
                        Attach Photo
                      </Button>
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={(el) => {
                          const key = `${storeId}-${cat.id}`;
                          fileInputRefs.current[key] = el;
                        }}
                        onChange={(ev) => handleFileChange(cat.id, ev)}
                      />

                      {/* If user just attached a new file, show preview */}
                      {photoUploads[storeId] &&
                       photoUploads[storeId][cat.id] && (
                        <>
                          <Text fontSize="sm" mt={1}>
                            Attached File: {photoUploads[storeId][cat.id].name}
                          </Text>
                          <Box mt={2}>
                            <img
                              src={URL.createObjectURL(photoUploads[storeId][cat.id])}
                              alt="New Preview"
                              style={{ maxWidth: '100px', maxHeight: '80px' }}
                            />
                          </Box>
                        </>
                      )}

                      {/* If no new file but existing photoUrl from DB => show that */}
                      {!(
                        photoUploads[storeId] &&
                        photoUploads[storeId][cat.id]
                      ) && currentNotes[`${cat.id}_photoUrl`] && (
                        <Box mt={2}>
                          <Text fontSize="sm">Previously uploaded:</Text>
                          <img
                            src={currentNotes[`${cat.id}_photoUrl`]}
                            alt="Existing Preview"
                            style={{ maxWidth: '100px', maxHeight: '80px' }}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              );
            })}
          </VStack>
        </ModalBody>

        <ModalFooter borderTop="1px solid" borderColor="gray.200">
          <Flex align="center" justify="flex-end" width="100%">
            <Button variant="ghost" onClick={onClose} mr={3}>
              Cancel
            </Button>

            {/* Hide the "Save" button if there are NO unsaved changes */}
            {hasChanges && (
              <Button
                variant="solid"
                colorScheme="blue"
                onClick={handleSaveDraft}
                mr={3}
              >
                Save
              </Button>
            )}

            {/* Final Data button is ALWAYS visible/enabled */}
            <Button
              variant="solid"
              colorScheme="green"
              onClick={handleSubmitFinalData}
            >
              Submit Final Data
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
