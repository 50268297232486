// File: src/components/ProlifiField/Schedules/DashboardSection.jsx

import React from 'react';
import WeeklyRequirements from './WeeklyRequirements';
import MonthlyRequirements from './MonthlyRequirements';
import WeekRangeSelector from './WeekRangeSelector';

export default function DashboardSection({
  collapsed,
  onToggle,
  monthlyData = [],
  cycleLength = 3,
  patternWarnings = [],
  currentView,
  events,
  storeData,
  startDate,
  endDate,
  onWeekSelected,
  // (A) Now we have weeklyData from MySchedulerContainer
  weeklyData = [],
}) {
  return (
    <div
      style={{
        ...styles.dashboardOuter,
        height: collapsed ? '30px' : '300px',
      }}
    >
      <div style={styles.collapseBar} onClick={onToggle}>
        <div style={styles.collapseBarContent}>
          {collapsed ? 'Show Requirements ▾' : 'Hide Requirements ▴'}
        </div>
      </div>

      <div style={styles.requirementsBody}>
        {/* 
          LEFT => Weekly Requirements 
          - We display warnings & pass in weeklyData from the server’s pfield_dashboardData 
          - patternWarnings if you want to show any scheduling pattern warnings
        */}
        <div style={{ ...styles.dashboardLeft, borderRight: '1px solid #ddd' }}>
          <WeeklyRequirements
            weeklyData={weeklyData}
            patternWarnings={patternWarnings}
            startDate={startDate}
            endDate={endDate}
          />
        </div>

        {/* 
          MIDDLE => narrower container for the WeekRangeSelector 
          - This includes the arrow buttons, date range label, 
            and "This Week's Hours" aggregator logic 
        */}
        <div style={{ ...styles.dashboardMiddle, borderRight: '1px solid #ddd' }}>
          <WeekRangeSelector
            startDate={startDate}
            endDate={endDate}
            onWeekSelected={onWeekSelected}
            // If you want to show “This Week’s Hours” in the selector itself,
            // you can sum them up from weeklyData right here:
            weeklyOverallHours={sumWeeklyHours(weeklyData)}
            weeklyData={weeklyData}
          />
        </div>

        {/* 
          RIGHT => Monthly Requirements 
          - We pass in monthlyData & cycleLength 
        */}
        <div style={styles.dashboardRight}>
          <MonthlyRequirements monthlyData={monthlyData} cycleLength={cycleLength} />
        </div>
      </div>
    </div>
  );
}

/**
 * Helper: Sums up all “scheduled” hours from the weeklyData array
 * so that we can pass it into the WeekRangeSelector as weeklyOverallHours.
 */
function sumWeeklyHours(weeklyData) {
  if (!Array.isArray(weeklyData)) {
    return 0;
  }

  return weeklyData.reduce((acc, row) => acc + (row.scheduled || 0), 0);
}

const styles = {
  dashboardOuter: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    margin: '1px',
    marginBottom: '-20px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
    overflow: 'hidden',
    transition: 'height 0.3s ease',
    position: 'relative',
    padding: '2px',
    transform: 'scale(1, 0.85)',
    transformOrigin: 'top left',
  },
  collapseBar: {
    height: '30px',
    background: 'linear-gradient(to right, #f8f8f8, #ececec)',
    borderBottom: '1px solid #ddd',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  collapseBarContent: {
    fontSize: '0.9rem',
    color: '#333',
    fontWeight: 500,
  },
  requirementsBody: {
    flex: 1,
    display: 'flex',
    maxHeight: '99%',
    overflow: 'auto',
    fontSize: '0.8rem',
  },
  dashboardLeft: {
    flex: 1,
    padding: '1rem',
    minWidth: '220px',
    position: 'relative',
  },
  dashboardMiddle: {
    flex: '0 0 auto',
    width: '300px',
    padding: '.5rem',
    borderRight: '1px solid #ddd',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  dashboardRight: {
    flex: 1,
    padding: '1rem',
    minWidth: '220px',
    position: 'relative',
  },
};
