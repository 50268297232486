// src/components/ShopifyStore.js

import React, { useEffect } from "react";

/**
 * A React component that embeds a Shopify "Buy Button" Collection
 * in a side-by-side layout (up to 4 columns on larger screens),
 * with a carefully structured layout so images, title, price,
 * variants, and the button align in a uniform way.
 */
function ShopifyStore() {
  useEffect(() => {
    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

    function ShopifyBuyInit() {
      // Build your Shopify client
      const client = window.ShopifyBuy.buildClient({
        domain: "r0ttdc-ie.myshopify.com",
        storefrontAccessToken: "2bd6eda21529e91d9ee2d53b843215b0",
      });

      if (window.ShopifyBuy.UI) {
        // When ShopifyBuy is ready, create our collection
        window.ShopifyBuy.UI.onReady(client).then((ui) => {
          ui.createComponent("collection", {
            id: "454129942756",
            node: document.getElementById("collection-component-1740940389516"),
            moneyFormat: "%24%7B%7Bamount%7D%7D",

            /**
             * contents => specify what shows in each product card
             * By setting these to `true` or `false` you can control
             * whether we show the product image, title, price, options, etc.
             */
            options: {
              // We'll display image, title, price, variant options, and the button
              product: {
                contents: {
                  img: true,
                  title: true,
                  price: true,
                  options: true,      // show variant options
                  quantity: false,    // hide quantity field
                  button: true,       // show add-to-cart button
                  description: false, // if you want the description, set this true
                },
                styles: {
                  product: {
                    // Display as a column so we can space out each section:
                    "display": "flex",
                    "flex-direction": "column",
                    /**
                     * 'align-items: flex-start' ensures the text
                     * (title, price, variants) is left-aligned
                     * in a uniform column.
                     */
                    "align-items": "flex-start",

                    // For spacing the button at the bottom if you wish:
                    "justify-content": "flex-start",

                    /**
                     * On larger screens, we have 4 columns across.
                     * This width is controlled by productSet -> products (flex wrap).
                     * We'll keep margin-bottom for vertical spacing.
                     */
                    "@media (min-width: 601px)": {
                      "width": "calc(25% - 0px)",
                      "margin-bottom": "50px",
                    },

                    /**
                     * We keep a min-height so each card is the same height
                     * (if your products vary a lot in variant count or description,
                     * you may need to increase this).
                     */
                    "min-height": "560px",

                    "font-family": "'Inter', sans-serif",
                    "color": "#333",
                    "background-color": "#fff",
                    "border": "1px solid #e2e2e2",
                    "border-radius": "6px",
                    "padding": "16px",
                    "box-shadow": "0 1px 3px rgba(40, 117, 199, 0.15)",
                    "transition": "transform 0.2s ease-in-out, box-shadow 0.2s",

                    ":hover": {
                      "transform": "translateY(-2px)",
                      "box-shadow": "0 4px 8px rgba(40, 117, 199, 0.2)",
                    },

                    // Keep the image aspect ratio
                    imgWrapper: {
                      "position": "relative",
                      "height": "0",
                      "padding-top": "75%",
                      "overflow": "hidden",
                      "border-radius": "4px",
                      "margin-bottom": "12px",
                      "width": "100%",
                    },
                    img: {
                      "position": "absolute",
                      "top": "0",
                      "left": "0",
                      "width": "100%",
                      "height": "100%",
                      "object-fit": "cover",
                    },
                  },

                  // Title styling => margin helps align them uniformly
                  title: {
                    "font-size": "16px",
                    "font-weight": "600",
                    "color": "#1f2937",
					"margin-top": "10px",
                    "margin-bottom": "8px",
                  },

                  // Price styling => keep margins consistent
                  price: {
                    "font-size": "14px",
                    "color": "#4b5563",
                    "margin-bottom": "-10px",
                  },

                  // Compare-at price styling => older/higher prices
                  compareAt: {
                    "font-size": "12px",
                    "color": "#9ca3af",
                  },

                  /**
                   * The variant selector area
                   * We add margin to line up with the rest
                   */
                  options: {
                    "margin-bottom": "12px",
                    /**
                     * If you want each variant dropdown
                     * to have a consistent width, you can do:
                     * "select": {
                     *   "width": "100%",
                     * }
                     */
                  },

                  // Blue "Add to cart" button
                  button: {
                    "font-family": "'Inter', sans-serif",
                    "font-size": "14px",
                    "font-weight": "600",
                    "color": "#ffffff",
                    "background-color": "#2875c7",
                    "padding": "10px 16px",
                    "border-radius": "4px",
                    "border": "none",
                    ":hover": {
                      "background-color": "#1f60a3",
                    },
                    ":focus": {
                      "background-color": "#1f60a3",
                      "outline": "none",
                    },
                    /**
                     * This margin-top ensures there's space above the button,
                     * so it's separated from the variant dropdowns
                     */
                    "margin-top": "auto",
                  },
                },
                text: {
                  button: "Add to cart",
                },
              },

              // 1) Override how the entire product grid (all products) is displayed
              productSet: {
                styles: {
                  products: {
                    "display": "flex",
                    "flex-wrap": "wrap",
                    "justify-content": "center",
                    "margin-left": "0",
                    "gap": "20px",
                  },
                },
              },

              // 3) Modal product (quick view popup)
              modalProduct: {
                contents: {
                  img: false,
                  imgWithCarousel: true,
                  button: false,
                  buttonWithQuantity: true,
                },
                styles: {
                  product: {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-left": "0px",
                      "margin-bottom": "0px",
                    },
                    "font-family": "'Inter', sans-serif",
                  },
                  button: {
                    "font-family": "'Inter', sans-serif",
                    "font-size": "14px",
                    "font-weight": "600",
                    "color": "#fff",
                    "background-color": "#2875c7",
                    "border-radius": "4px",
                    "border": "none",
                    ":hover": {
                      "background-color": "#1f60a3",
                    },
                    ":focus": {
                      "background-color": "#1f60a3",
                      "outline": "none",
                    },
                  },
                },
                text: {
                  button: "Add to cart",
                },
              },

              // 4) Cart styling
              cart: {
                styles: {
                  button: {
                    "font-family": "'Inter', sans-serif",
                    "font-size": "14px",
                    "font-weight": "600",
                    "color": "#ffffff",
                    "background-color": "#2875c7",
                    "border-radius": "4px",
                    ":hover": {
                      "background-color": "#1f60a3",
                    },
                    ":focus": {
                      "background-color": "#1f60a3",
                      "outline": "none",
                    },
                  },
                  footer: {
                    "background-color": "#f8f8f8",
                  },
                },
                text: {
                  title: "ProlifiCart",
                  total: "Subtotal",
                  empty: "Your cart is empty.",
                  button: "Checkout",
                },
              },

              // 5) Floating cart toggle
              toggle: {
                styles: {
                  toggle: {
                    "background-color": "#2875c7",
                    ":hover": {
                      "background-color": "#1f60a3",
                    },
                    ":focus": {
                      "background-color": "#1f60a3",
                      "outline": "none",
                    },
                  },
                  count: {
                    "font-size": "12px",
                  },
                },
              },
            },
          });
        });
      }
    }

    // Load the Shopify script if needed
    const scriptTag = document.querySelector(`script[src="${scriptURL}"]`);
    if (scriptTag) {
      // Script is already in the DOM
      if (window.ShopifyBuy && window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      }
    } else {
      // Inject the script
      const newScript = document.createElement("script");
      newScript.async = true;
      newScript.src = scriptURL;
      newScript.onload = () => {
        if (window.ShopifyBuy && window.ShopifyBuy.UI) {
          ShopifyBuyInit();
        }
      };
      document.head.appendChild(newScript);
    }
  }, []);

  return (
    <div
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        fontFamily: "'Inter', sans-serif",
      }}
    >
      <h2
        style={{
          fontSize: "36px",
          marginBottom: "32px",
          textAlign: "center",
          color: "#1f2937",
          fontWeight: 700,
          letterSpacing: "1px",
        }}
      >
        ProlifiSHOP
      </h2>
      {/* Here Shopify injects the product grid */}
      <div id="collection-component-1740940389516"></div>
    </div>
  );
}

export default ShopifyStore;
