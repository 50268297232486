// File: src/components/ProlifiField/Schedules/ProlifiFieldLayout.jsx
import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import ProlifiFieldMiniNav from "./ProlifiFieldMiniNav";
import ProlifiFieldAnnouncementModal from "./ProlifiFieldAnnouncementModal";

function ProlifiFieldLayout({ userGroup, isImpersonating }) {
  // If an announcement might appear, we wait to redirect until user either dismisses or never sees it
  const [announcementCleared, setAnnouncementCleared] = useState(false);

  // Show/hide the top mini-nav after 1.5s
  const [navExpanded, setNavExpanded] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  
  console.log("USERGROUP: ", userGroup);

  // Determine the user's role in a case-insensitive way.
  const role =
    typeof userGroup === "string"
      ? userGroup.toLowerCase()
      : (userGroup?.userGroup || "").toLowerCase();
	  
  console.log("ROLE: ", role);

  // Which route to load by default (director or normal schedule)
  const scheduleRoute =
    role === "director"
      ? "/prolifi-schedules/director-schedule"
      : "/prolifi-schedules/schedule";

  /**
   * 1) Only if user is on "/prolifi-schedules" exactly
   *    AND the announcement is "cleared", we do the navigate.
   */
  useEffect(() => {
    if (
      announcementCleared &&
      (location.pathname === "/prolifi-schedules" ||
       location.pathname === "/prolifi-schedules/")
    ) {
      navigate(scheduleRoute, { replace: true });
    }
  }, [announcementCleared, location.pathname, navigate, scheduleRoute]);

  /**
   * 2) Auto-collapse nav after 1.5s
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setNavExpanded(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  /**
   * Callback from our announcement modal => user dismissed or never saw it
   */
  const handleAnnouncementDismiss = () => {
    setAnnouncementCleared(true);
  };

  return (
    <Flex direction="column" position="relative" width="99vw" bg="white">
      {/* The mini nav is absolutely positioned above the main content. */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height={navExpanded ? "30px" : "5px"}
        transition="height 0.2s ease-in-out"
        overflow="hidden"
        zIndex={3}
      >
        <ProlifiFieldMiniNav onExpand={setNavExpanded} userGroup={userGroup} />
      </Box>

      {/* Main content behind the mini nav. */}
      <Box flex="1" position="relative" zIndex={4} ml="10px">
        {/* Render the announcement modal once, pass a dismiss callback */}
        <ProlifiFieldAnnouncementModal onDismiss={handleAnnouncementDismiss} />

        <Outlet context={{ isImpersonating }} />
      </Box>
    </Flex>
  );
}

export default ProlifiFieldLayout;
