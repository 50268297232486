// File: src/components/ProlifiField/Schedules/wsn/director/SupervisorWeeklyStoreNotesModal.jsx
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getBaseUrl } from '../../../../utils/getBaseUrl';
import './SupervisorWeeklyStoreNotesModal.css';
import StoreByStoreView from './StoreByStoreView';
import SpreadsheetView from './SpreadsheetView';
import ModalRequirementsSummary from './ModalRequirementsSummary'; // NEW import
import { Box, Text, Button } from '@chakra-ui/react';

// Helper function to format a Date as YYYY-MM-DD (using local time)
function formatDate(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// Helper: Returns default week range (previous Monday to Sunday) if startDate/endDate are undefined.
function getDefaultWeekRange() {
  const now = new Date();
  const diffToCurrentMonday = now.getDay() === 0 ? 6 : now.getDay() - 1;
  const currentMonday = new Date(now);
  currentMonday.setDate(now.getDate() - diffToCurrentMonday);
  currentMonday.setHours(0, 0, 0, 0);
  const previousMonday = new Date(currentMonday);
  previousMonday.setDate(currentMonday.getDate() - 7);
  const previousSunday = new Date(previousMonday);
  previousSunday.setDate(previousMonday.getDate() + 6);
  return { start: formatDate(previousMonday), end: formatDate(previousSunday) };
}

export default function SupervisorWeeklyStoreNotesModal({
  isOpen,
  onClose,
  supervisors,
  initialSupervisorId,
  // These props can be optionally provided, but we'll override with our axios call.
  weeklyData: propWeeklyData = [],
  monthlyData: propMonthlyData = [],
  patternWarnings = [],
  startDate,
  endDate
}) {
  // Filter supervisors as before.
  const filteredSupervisors = useMemo(() => {
    return supervisors.filter(s => Number(s.id) < 10000);
  }, [supervisors]);

  // State for supervisor selection, store navigation, and view mode.
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentStoreIndex, setCurrentStoreIndex] = useState(0);
  const [notesData, setNotesData] = useState(null);
  const [storesData, setStoresData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // New state for toggling between views: 'storeByStore' vs. 'spreadsheet'
  const [viewMode, setViewMode] = useState('storeByStore');

  // New state for dashboard data (weekly and monthly warnings)
  const [dashboardData, setDashboardData] = useState(null);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [dashboardError, setDashboardError] = useState(null);

  // Set the current supervisor when the modal opens.
  useEffect(() => {
    if (isOpen && initialSupervisorId != null && filteredSupervisors.length > 0) {
      const index = filteredSupervisors.findIndex(sup => sup.id === initialSupervisorId);
      setCurrentIndex(index !== -1 ? index : 0);
      setCurrentStoreIndex(0);
    }
  }, [isOpen, initialSupervisorId, filteredSupervisors]);

  // Fetch store list and weekly notes when supervisor changes.
  useEffect(() => {
    if (!isOpen) return;
    const currentSupervisor = filteredSupervisors[currentIndex];
    if (!currentSupervisor) return;

    setLoading(true);
    setError(null);

    axios
      .get(`${getBaseUrl()}/pfield_wsn/supervisorStores`, {
        params: { supervisorId: currentSupervisor.id },
        withCredentials: true
      })
      .then(storeRes => {
        const storeList = storeRes.data;
        setStoresData(storeList);
        if (!storeList || storeList.length === 0) {
          setNotesData(null);
          setLoading(false);
          return;
        }
        const storeIds = storeList.map(store => store.store_id);
        const storeIdsParam = storeIds.join(',');

        // Calculate previous week's Monday to Sunday date range.
        const now = new Date();
        const diffToCurrentMonday = now.getDay() === 0 ? 6 : now.getDay() - 1;
        const currentMonday = new Date(now);
        currentMonday.setDate(now.getDate() - diffToCurrentMonday);
        currentMonday.setHours(0, 0, 0, 0);
        const previousMonday = new Date(currentMonday);
        previousMonday.setDate(currentMonday.getDate() - 7);
        const previousSunday = new Date(previousMonday);
        previousSunday.setDate(previousMonday.getDate() + 6);
        const start = formatDate(previousMonday);
        const end = formatDate(previousSunday);

        return axios.get(`${getBaseUrl()}/pfield_wsn/modalWeekData`, {
          params: { start, end, storeIds: storeIdsParam },
          withCredentials: true
        });
      })
      .then(weekDataRes => {
        if (weekDataRes) {
          setNotesData(weekDataRes.data);
          setCurrentStoreIndex(0);
        }
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching weekly store notes:', err);
        setError('Error fetching weekly store notes.');
        setLoading(false);
      });
  }, [isOpen, filteredSupervisors, currentIndex]);

// Fetch dashboard data (weekly and monthly) from backend.
useEffect(() => {
  if (!isOpen) return;
  if (filteredSupervisors.length === 0) return;
  
  const currentSupervisor = filteredSupervisors[currentIndex];
  if (!currentSupervisor) return;
  
  // Use provided startDate and endDate if available; otherwise, compute defaults.
  const { start, end } = startDate && endDate ? { start: startDate, end: endDate } : getDefaultWeekRange();
  setLoadingDashboard(true);
  setDashboardError(null);
  // Derive month from start (YYYY-MM)
  const month = start.slice(0, 7);
  // Append time components for weeklyStart and weeklyEnd.
  const weeklyStart = `${start} 00:00:00`;
  const weeklyEnd = `${end} 23:59:59`;

  console.log('[Dashboard] weeklyStart:', weeklyStart);
  console.log('[Dashboard] weeklyEnd:', weeklyEnd);

  axios
    .get(`${getBaseUrl()}/pfield_wsn/supervisorDashboardData`, {
      params: { month, weeklyStart, weeklyEnd, supervisorId: currentSupervisor.id },
      withCredentials: true
    })
    .then((res) => {
      setDashboardData(res.data);
      setLoadingDashboard(false);
    })
    .catch((err) => {
      console.error('Error fetching dashboard data:', err);
      setDashboardError('Error fetching dashboard data.');
      setLoadingDashboard(false);
    });
}, [isOpen, startDate, endDate, currentIndex, filteredSupervisors]);


  // Supervisor navigation handlers.
  const goToPreviousSupervisor = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentStoreIndex(0);
      setNotesData(null);
    }
  };

  const goToNextSupervisor = () => {
    if (currentIndex < filteredSupervisors.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentStoreIndex(0);
      setNotesData(null);
    }
  };

  // Compute the list of stores and the current store page.
  const storesArray = notesData ? Object.entries(notesData) : [];
  const currentStorePage = storesArray.length > 0 ? storesArray[currentStoreIndex] : null;

  // Store navigation handlers.
  const goToPreviousStore = () => {
    if (currentStoreIndex > 0) {
      setCurrentStoreIndex(currentStoreIndex - 1);
    }
  };

  const goToNextStore = () => {
    if (currentStoreIndex < storesArray.length - 1) {
      setCurrentStoreIndex(currentStoreIndex + 1);
    }
  };

  // Get the display name of the current store.
  const getStoreDisplayName = () => {
    if (currentStorePage && storesData.length > 0) {
      const storeIdKey = currentStorePage[0];
      const storeInfo = storesData.find(s => String(s.store_id) === String(storeIdKey));
      return storeInfo ? storeInfo.store_name : storeIdKey;
    }
    return '';
  };

  // Toggle between "store by store" and "spreadsheet" views.
  const toggleViewMode = () => {
    setViewMode(prev => (prev === 'storeByStore' ? 'spreadsheet' : 'storeByStore'));
  };

  if (!isOpen) return null;
  const currentSupervisor = filteredSupervisors[currentIndex];

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Weekly Store Notes Review</h3>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        {/* Supervisor Navigation */}
        <div className="supervisor-navigation">
          <button className="nav-button" onClick={goToPreviousSupervisor} disabled={currentIndex === 0}>
            &#8592; Prev
          </button>
          <span className="supervisor-name">{currentSupervisor.text}</span>
          <button
            className="nav-button"
            onClick={goToNextSupervisor}
            disabled={currentIndex === filteredSupervisors.length - 1}
          >
            Next &#8594;
          </button>
        </div>
        {/* Requirements Summary Section placed just below the supervisor selector */}
        {loadingDashboard ? (
          <Box textAlign="center" my={4}>
            <Text>Loading requirements...</Text>
          </Box>
        ) : dashboardError ? (
          <Box textAlign="center" my={4}>
            <Text color="red.600">{dashboardError}</Text>
          </Box>
        ) : (
		<ModalRequirementsSummary
		  weeklyData={dashboardData ? dashboardData.weekly : []}
		  monthlyData={dashboardData ? dashboardData.monthly : []}
		  cycleLength={dashboardData ? dashboardData.cycleLength : 3}
		  patternWarnings={[]}
		  startDate={startDate || getDefaultWeekRange().start}
		  endDate={endDate || getDefaultWeekRange().end}
		  stores={storesData}  // Pass the complete list of stores for this supervisor
		/>
        )}

        <div className="modal-body">
          {loading && <p className="status-message">Loading store notes...</p>}
          {error && <p className="error">{error}</p>}
          {!loading &&
            notesData &&
            viewMode === 'storeByStore' && (
              <StoreByStoreView
                currentStorePage={currentStorePage}
                storeDisplayName={getStoreDisplayName()}
                currentStoreIndex={currentStoreIndex}
                totalStores={storesArray.length}
                goToPreviousStore={goToPreviousStore}
                goToNextStore={goToNextStore}
                storesData={storesData}
              />
            )}
          {!loading &&
            notesData &&
            viewMode === 'spreadsheet' && (
              <SpreadsheetView notesData={notesData} storesData={storesData} />
            )}
          {!loading && (!notesData || storesArray.length === 0) && (
            <p className="status-message">No store notes available for this supervisor.</p>
          )}
        </div>
      </div>
    </div>
  );
}

SupervisorWeeklyStoreNotesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  supervisors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  initialSupervisorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weeklyData: PropTypes.array,
  monthlyData: PropTypes.array,
  patternWarnings: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};
