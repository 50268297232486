// src/components/Dashboard.js
import React from "react";
import { useNavigate } from "react-router-dom";
import TZv2Stores from "./TZv2Stores";

function Dashboard({ userGroup, company, username, userStore }) {
  const navigate = useNavigate();

  const handleShopClick = () => {
    // Navigate to the ProlifiShop route
    navigate("/Prolifi-Shop");
  };

  return (
    <div>
      {/* Announcement Section */}
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: ".75rem",
          background: "#ffffff",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          marginBottom: "1rem",
          textAlign: "center",
        }}
      >

        <p
          style={{
            fontSize: "1.125rem",
            color: "#555",
            marginBottom: "0.5rem",
          }}
        >
          Micros Employee Swipe Cards as low as{" "}
          <strong>$0.50/Card</strong> + Free Shipping!
        </p>
        <p
          style={{
            fontSize: "0.875rem",
            color: "#888",
            marginBottom: "1.5rem",
          }}
        >
          *Free shipping applies on quantities of 250 or more.
        </p>
        <button
          onClick={handleShopClick}
          style={{
            background: "#007bff",
            color: "#fff",
            padding: "0.75rem 1.5rem",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "1rem",
            transition: "background 0.3s ease",
          }}
          onMouseOver={(e) => (e.currentTarget.style.background = "#0056b3")}
          onMouseOut={(e) => (e.currentTarget.style.background = "#007bff")}
        >
          Visit ProlifiShop
        </button>
      </section>

      {/* Tot Zone Stores Component */}
      <TZv2Stores
        userGroup={userGroup}
        company={company}
        username={username}
        userStore={userStore}
      />
    </div>
  );
}

export default Dashboard;
